import { MathUtils, Vector3 } from 'three';
import { Line } from '@react-three/drei';

const drawCircle = (radius: number) => {
  const points = [];
  for (let i = 0; i <= 360; i++) {
    points.push(
      new Vector3(
        Math.sin(MathUtils.degToRad(i)) * radius,
        Math.cos(MathUtils.degToRad(i)) * radius,
        0
      )
    );
  }

  return points;
};

export const Circle = ({
  radius,
  rotation
}: {
  radius: number;
  rotation?: [number, number, number];
}) => {
  return (
    <Line
      points={drawCircle(radius)}
      color={0xffffff}
      lineWidth={3}
      opacity={0.1}
      transparent
      rotation={rotation ?? [0, 0, 0]}
    />
  );
};
