import { SetStateAction, useState } from 'react';
import { Tabs, TabsContent, TabsList } from '../ui/tabs';
import { StyledTabsTrigger } from '../Common/Tab/';
import { MouseDetails } from './MouseDetails';
import { TouchDetails } from './TouchDetails';
import { KeyboardDetails } from './KeyboardDetails';
import { useDeviceType, useElementSize } from '@/hooks';
import { useWindowSize } from 'usehooks-ts';

export const HelpView = ({ topOffset }: { topOffset: number }) => {
  const [tab, setTab] = useState('mouse');
  const { height: windowHeight } = useWindowSize();
  const [navControlRef, { height: navControlHeight }] = useElementSize();
  const ackMaxHeight = windowHeight - navControlHeight - topOffset - 140; // 140 is the height of other elements
  const { isMobile } = useDeviceType();
  const onTabChange = (value: SetStateAction<string>) => {
    setTab(value);
  };

  return (
    <div className="mx-6 mt-8">
      <div className="flex flex-col gap-5">
        <div ref={navControlRef}>
          <h4 className="text-xl font-bold">Information</h4>
          <div className="text-lg font-bold mt-8">Navigation controls</div>
          <div className="flex flex-col mt-5 text-base font-normal">
            <Tabs value={tab} onValueChange={onTabChange}>
              <TabsList className="w-full justify-between text-foreground bg-background p-0 mt-4">
                <StyledTabsTrigger value="mouse">Mouse</StyledTabsTrigger>
                <StyledTabsTrigger value="touch">Touch</StyledTabsTrigger>
                <StyledTabsTrigger value="keyboard">Keyboard</StyledTabsTrigger>
              </TabsList>
              <TabsContent value="mouse">
                <MouseDetails />
              </TabsContent>
              <TabsContent value="touch">
                <TouchDetails />
              </TabsContent>
              <TabsContent className="overflow-x-auto" value="keyboard">
                <KeyboardDetails />
              </TabsContent>
            </Tabs>
          </div>
          {!isMobile ? (
            <div className="flex items-center">
              <div className="flex-1 border-b-2 border-[#303030] mt-5 mb-5"></div>
            </div>
          ) : null}
        </div>
        <div className="text-lg font-bold">Acknowledgments</div>
        <div
          className="text-base normal mb-32 overflow-y-auto"
          style={{
            height: isMobile ? `${ackMaxHeight}px` : undefined
          }}
        >
          The digital reproductions presented in this viewer were generated from human tissue
          obtained by Curtin University for the strict purpose of education and research and are
          regulated under the Anatomy Act (1930) and Tissue and Transplant Act (1982) of Western
          Australia.
          <br />
          <br />
          Curtin University would like to acknowledge those who have donated their bodies to the
          Western Australian Body Bequest program. Development of this resource would not have been
          possible without their generous gift.
        </div>
      </div>
    </div>
  );
};
