import { useState } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { CameraControls, Html } from '@react-three/drei';
import { useElementSize } from '@/hooks';
import { AxesLabels } from '#server/models';
import { getCurrentAxisLabel } from './service';

export const AxesLabel = ({
  axesLabels,
  cameraControlsRef
}: {
  axesLabels: AxesLabels;
  cameraControlsRef: React.MutableRefObject<CameraControls | null>;
}) => {
  const [axesLabel, setAxesLabel] = useState<string>('');
  const { size } = useThree();
  const [htmlRef, { width: htmlWidth }] = useElementSize();

  useFrame(() => {
    if (cameraControlsRef.current) {
      const calculatedAxesLabel = getCurrentAxisLabel(
        cameraControlsRef.current.polarAngle,
        cameraControlsRef.current.azimuthAngle,
        axesLabels
      );
      if (axesLabel !== calculatedAxesLabel) {
        setAxesLabel(calculatedAxesLabel);
      }
    }
    if (htmlRef?.current?.parentElement) {
      // Override position set by react-three-drei to ensure that the label remains in a fixed place
      // This is a bit of a nasty/inefficient way of doing this but there doesn't seem to be another way to
      // use HTML with a position fixed relative to camera.
      htmlRef.current.parentElement.style.transform = `translate3d(${size.width / 2}px, ${size.height * 0.9}px, 0px)`;
    }
  });

  return (
    <Html
      ref={htmlRef}
      style={{ left: -(htmlWidth / 2) }}
      className="rounded-2xl border-white border-solid border-[0.5px] py-1 px-4 whitespace-nowrap
        fixed translate-y-1/2 uppercase leading-normal tracking-[0.3px] font-normal text-xl"
    >
      {axesLabel}
    </Html>
  );
};
