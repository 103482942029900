import ReactDOM from 'react-dom/client';
import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProvider } from 'react-oidc-context';
import App from './App';
import { authConfig } from './env-exports';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorHandler } from '@/components/Common/ErrorHandler';

const root = ReactDOM.createRoot(document.querySelector('#root')!);

const oidcConfig = {
  authority: authConfig.authority,
  client_id: authConfig.clientId,
  scope: authConfig.scopes,
  response_type: 'code',
  redirect_uri: authConfig.redirectUri,
  userStore: new WebStorageStateStore({ store: window.localStorage })
};

root.render(
  <ErrorBoundary FallbackComponent={ErrorHandler}>
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
  </ErrorBoundary>
);
