import { ViewerType } from '#server/models';
import { trpc } from '@/data-loaders';

export const useSubjectConfig = (
  subjectSlug = '',
  subjectType: ViewerType = '3D',
  areaSlug = ''
) => {
  return trpc.getSubjectConfig.useQuery(
    { subjectSlug, subjectType, areaSlug },
    {
      retry: false,
      cacheTime: 1000 * 60 * 60 * 8, // cache for 8 hours
      staleTime: 1000 * 60 * 60 * 8, // don't consider the data stale until 8 hours
      enabled: !!areaSlug && !!subjectSlug
    }
  );
};
