import { ChangeEventHandler } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MagnifyingGlassIcon, Input, SearchLabelState } from '@/components';
import { AreaConfig, LabelConfig, SubjectConfig } from '#server/models';
import { SearchFilterDropDown } from './SearchFilterDropDown';
import { SubjectViewState } from '../ThreeDeeViewer/Experience/Subject';
import { DXPAnalytics } from '@curtin-dxp/web-client';
import { getCommonAnalyticsContext } from '@/lib/utils';

export interface SearchLabelsProps {
  state: SearchLabelState;
  setState: (searchLabelsState: SearchLabelState) => void;
  onChangeLabelSearchFilter: ChangeEventHandler<HTMLInputElement> | undefined;
  getUpdatedLabels: () => LabelConfig[] | undefined;
  modelConfig: SubjectConfig | undefined;
  areaConfig: AreaConfig | undefined;
  subjectViewState: SubjectViewState;
  setSubjectViewState: (subjectViewState: SubjectViewState) => void;
}
export const SearchLabels = ({
  state,
  setState,
  onChangeLabelSearchFilter,
  getUpdatedLabels,
  modelConfig,
  areaConfig,
  subjectViewState,
  setSubjectViewState
}: SearchLabelsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const structures =
    areaConfig?.categoryGroups
      .find((g) => g.isPrimary)
      ?.categories.sort((a, b) => a.name.localeCompare(b.name)) ?? [];
  const units = areaConfig?.units.sort((a, b) => a.udc.localeCompare(b.udc)) ?? [];

  const onClickLabel = (labelId: string) => {
    searchParams.set('selectedLabel', labelId);
    setSearchParams(searchParams);
    setSubjectViewState({ ...subjectViewState, labelsOn: true });
    DXPAnalytics.trackAction({
      name: 'SELECT_LABEL_FROM_LIST',
      context: {
        ...getCommonAnalyticsContext(),
        context5: labelId
      }
    });
  };

  const modelStructureIds = modelConfig?.labels.map((l) => l.primaryCategoryId.id) ?? [];
  const modelUnitIds = modelConfig?.labels.flatMap((l) => l.unitIds) ?? [];
  const modelStructures = structures.filter((structure) =>
    modelStructureIds.includes(structure.id)
  );
  const modelUnits = units.filter((unit) => modelUnitIds.includes(unit.id));

  const labels = getUpdatedLabels();

  return (
    <div className="flex flex-col">
      <div className="px-4 pt-8 pb-4">
        <div className="relative">
          <Input
            aria-label="Search for a label"
            placeholder="Search for a label"
            className="bg-[#303030] border-none text-[#E8E8E8] placeholder:text-[#E8E8E8]
              focus-visible:ring-0 focus-visible:bg-[#4B4B4B]"
            onChange={onChangeLabelSearchFilter}
            value={state.labelSearchFilter}
          />
          <MagnifyingGlassIcon
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 z-10 h-4
              w-4"
          />
        </div>
      </div>
      <div
        className="portrait:max-h-[80vh] lg:landscape:max-h-[80vh] landscape:max-h-[70vh] px-4
          overflow-y-scroll scrollbar scrollbar-track-[#1A1A1A] scrollbar-thumb-[#303030]
          scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
      >
        <div>
          <div className="text-base flex justify-between">
            <p className="font-semibold">Filter by</p>
            <a
              href="#"
              className="text-[#C3D82E] underline"
              onClick={(e) => {
                e.preventDefault();
                setState({ ...state, unitsFilter: [], structuresFilter: [] });
                DXPAnalytics.trackAction({
                  name: 'RESET_LABEL_SEARCH_FILTERS',
                  context: getCommonAnalyticsContext()
                });
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  setState({ ...state, unitsFilter: [], structuresFilter: [] });
                  DXPAnalytics.trackAction({
                    name: 'RESET_LABEL_SEARCH_FILTERS',
                    context: getCommonAnalyticsContext()
                  });
                }
              }}
            >
              Reset all
            </a>
          </div>

          <SearchFilterDropDown
            state={state}
            setState={setState}
            stateFilterFieldName="unitsFilter"
            stateFilterValues={state.unitsFilter}
            fullOptionList={modelUnits}
            optionDisplayKey={'udc'}
            dropDownName="Unit"
          />
          <SearchFilterDropDown
            state={state}
            setState={setState}
            stateFilterFieldName="structuresFilter"
            stateFilterValues={state.structuresFilter}
            fullOptionList={modelStructures}
            optionDisplayKey={'name'}
            dropDownName="Structure category"
          />
        </div>
        <p className="font-semibold text-base mt-4">Labels</p>
        <ul className="mt-4 mb-32 flex flex-col">
          {labels?.map((label) => {
            const active = searchParams.get('selectedLabel') === label.slug;
            return (
              <li
                key={label.id}
                className="hover:bg-[#303030] data-[state=active]:bg-[#4B4B4B] py-2"
                data-state={active ? 'active' : 'inactive'}
              >
                <button
                  onClick={() => onClickLabel(label.slug)}
                  className="flex gap-4 items-center w-full"
                >
                  <span
                    className="w-4 h-4 rounded-full"
                    style={{
                      backgroundColor:
                        areaConfig?.categoryGroups
                          .find((gp) => gp.id === label.primaryCategoryId.groupId)
                          ?.categories.find((c) => c.id === label.primaryCategoryId.id)?.color! ??
                        '#E8E8E8',
                      border: active ? '2px solid #E8E8E8' : undefined
                    }}
                  ></span>
                  <span className="text-left">{label.title}</span>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
