import { ChangeEventHandler, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tabs, TabsContent, TabsList } from '../ui/tabs';
import { StyledTabsTrigger } from '../Common/Tab/';
import { SearchLabels } from './SearchLabels';
import { LabelDetails } from './LabelDetails';
import { AreaConfig, LabelConfig, SubjectConfig } from '#server/models';
import { SubjectViewState } from '../ThreeDeeViewer/Experience/Subject';
import { SearchLabelState } from '../Layout';
import { DXPAnalytics } from '@curtin-dxp/web-client';
import { getCommonAnalyticsContext } from '@/lib/utils';

export interface LabelsViewProps {
  searchLabelsState: SearchLabelState;
  setSearchLabelsState: (searchLabelsState: SearchLabelState) => void;
  onChangeLabelSearchFilter: ChangeEventHandler<HTMLInputElement> | undefined;
  getUpdatedLabels: () => LabelConfig[] | undefined;
  modelConfig: SubjectConfig | undefined;
  areaConfig: AreaConfig | undefined;
  subjectViewState: SubjectViewState;
  setSubjectViewState: (subjectViewState: SubjectViewState) => void;
}

export const LabelsView = ({
  searchLabelsState,
  setSearchLabelsState,
  onChangeLabelSearchFilter,
  getUpdatedLabels,
  modelConfig,
  areaConfig,
  subjectViewState,
  setSubjectViewState
}: LabelsViewProps) => {
  const [searchParams] = useSearchParams();
  const [tab, setTab] = useState('search');

  const onTabChange = (value: string) => {
    setTab(value);
    DXPAnalytics.trackAction({
      name: 'CHANGE_LABEL_TAB',
      context: { ...getCommonAnalyticsContext(), context5: value }
    });
  };

  useEffect(() => {
    const selectedLabelId = searchParams.get('selectedLabel');
    setTab(selectedLabelId ? 'details' : 'search');
  }, [searchParams]);

  return (
    <Tabs value={tab} onValueChange={onTabChange}>
      <TabsList className="w-full justify-between text-foreground bg-background p-4 mt-4">
        <StyledTabsTrigger value="search">Search labels</StyledTabsTrigger>
        <StyledTabsTrigger value="details">Label details</StyledTabsTrigger>
      </TabsList>
      <TabsContent value="search">
        <SearchLabels
          state={searchLabelsState}
          setState={setSearchLabelsState}
          onChangeLabelSearchFilter={onChangeLabelSearchFilter}
          getUpdatedLabels={getUpdatedLabels}
          modelConfig={modelConfig}
          areaConfig={areaConfig}
          subjectViewState={subjectViewState}
          setSubjectViewState={setSubjectViewState}
        />
      </TabsContent>
      <TabsContent value="details">
        <LabelDetails />
      </TabsContent>
    </Tabs>
  );
};
