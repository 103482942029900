export const MouseZoomIcon = () => (
  <svg width="30" height="41" viewBox="0 0 30 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.5 3H23.5C25.1569 3 26.5 4.34315 26.5 6V26.5C26.5 32.8513 21.3513 38 15 38C8.64873 38 3.5 32.8513 3.5 26.5V6C3.5 4.34315 4.84315 3 6.5 3H12.5L12.5 15C12.5 16.3807 13.6193 17.5 15 17.5C16.3807 17.5 17.5 16.3807 17.5 15V3ZM0.5 6C0.5 2.68629 3.18629 0 6.5 0H23.5C26.8137 0 29.5 2.68629 29.5 6V26.5C29.5 34.5081 23.0081 41 15 41C6.99187 41 0.5 34.5081 0.5 26.5V6Z"
      fill="#E8E8E8"
    />
  </svg>
);
