import { ToolboxViews } from '../Toolbox';

interface ToolboxIconProps {
  IconComponent: any;
  toolboxView?: ToolboxViews;
  toolboxState: {
    open: boolean;
    view: ToolboxViews;
  };
}

export const ToolboxIcon: React.FC<ToolboxIconProps> = ({
  IconComponent,
  toolboxView,
  toolboxState
}) => {
  return (
    <IconComponent
      className="h-5 w-5 stroke-[#F0F0F0] group-hover:stroke-[#000000] group-active:stroke-[#000000]
        data-[state=active]:stroke-[#000000] outline-none"
      data-state={toolboxState?.open && toolboxState?.view === toolboxView ? 'active' : 'inactive'}
      tabIndex={-1}
    />
  );
};
