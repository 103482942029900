import { Info, CircleUserRound, Expand, RefreshCw, Settings, SquarePen } from 'lucide-react';
import { toggleFullScreen } from '../Common/EventsHandler';
import { ToolboxIconButton } from './ToolboxIconButton';
import { ToolboxViews } from '../Toolbox';
import { forwardRef, useContext } from 'react';
import { useDeviceType } from '@/hooks';
import { FeaturesContext } from '@/context/FeaturesContext';

export interface ToolboxPanelProps {
  toolboxState: {
    open: boolean;
    view: ToolboxViews;
  };
  setToolboxView: (view: ToolboxViews) => void;
  toggleResetView: () => void;
  className?: string;
}

export const ToolboxPanel = forwardRef<HTMLDivElement, ToolboxPanelProps>(
  ({ toolboxState, setToolboxView, toggleResetView, className }, ref) => {
    const { isMobile } = useDeviceType();
    const { showUserProfile } = useContext(FeaturesContext);

    return (
      <div className={className} ref={ref}>
        {!isMobile && (
          <ToolboxIconButton
            IconComponent={SquarePen}
            tooltipContent={'Add annotation'}
            toolboxState={toolboxState}
            toolboxView="AddAnnotation"
            setToolboxView={setToolboxView}
          />
        )}
        <ToolboxIconButton
          IconComponent={Expand}
          tooltipContent={'Full screen'}
          toolboxState={toolboxState}
          clickEventHandler={toggleFullScreen}
          setToolboxView={setToolboxView}
        />
        <ToolboxIconButton
          IconComponent={RefreshCw}
          tooltipContent={'Reset view'}
          toolboxState={toolboxState}
          clickEventHandler={toggleResetView}
          setToolboxView={setToolboxView}
        />
        <ToolboxIconButton
          IconComponent={Settings}
          tooltipContent={'Settings'}
          toolboxState={toolboxState}
          toolboxView="Settings"
          setToolboxView={setToolboxView}
        />
        <ToolboxIconButton
          IconComponent={Info}
          tooltipContent={'Help'}
          toolboxState={toolboxState}
          toolboxView="Help"
          setToolboxView={setToolboxView}
        />
        {showUserProfile && (
          <ToolboxIconButton
            IconComponent={CircleUserRound}
            tooltipContent={'My profile'}
            toolboxState={toolboxState}
            toolboxView="Profile"
            setToolboxView={setToolboxView}
          />
        )}
      </div>
    );
  }
);
