import { AxesLabels } from '#server/models';

const AXIS_OFFSET = Number((Math.PI / 4).toFixed(6));

export const getCurrentAxisLabel = (
  polarAngle: number,
  azimuthAngle: number,
  axesLabels: AxesLabels
) => {
  // normalize phi/theta to PI and 2 * PI radians respectively.
  const phi = Math.max(0, Math.min(Math.PI, polarAngle));
  const theta = (azimuthAngle + 2 * Math.PI) % (2 * Math.PI);

  if (phi < AXIS_OFFSET) {
    return axesLabels.y1;
  } else if (phi > AXIS_OFFSET && phi < 3 * AXIS_OFFSET) {
    // if polar angle is middle, check angular coord
    if (theta < 1 * AXIS_OFFSET || theta > 2 * Math.PI - 1 * AXIS_OFFSET) {
      return axesLabels.z1;
    } else if (theta > 1 * AXIS_OFFSET && theta < 3 * AXIS_OFFSET) {
      return axesLabels.x1;
    } else if (theta > 3 * AXIS_OFFSET && theta < 5 * AXIS_OFFSET) {
      return axesLabels.z2;
    } else if (theta > 5 * AXIS_OFFSET && theta < 7 * AXIS_OFFSET) {
      return axesLabels.x2;
    }
  } else if (phi > 3 * AXIS_OFFSET) {
    return axesLabels.y2;
  }
  return '';
};
