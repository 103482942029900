import { BaseError } from '../BaseError';
import { AccessError } from '#server/models';

export interface AccessDeniedProps {
  error?: AccessError | undefined;
}

export const AccessDenied = ({ error }: AccessDeniedProps) => {
  const title = error?.errorTitle ?? 'Access denied';
  const paragraphs = error?.errorMessage
    ? error.errorMessage.split('\\n')
    : [
        'You do not have access to Curtin 3D Viewer.',
        'You are either not enrolled in a unit that supports access to the Viewer or you are not in the appropriate access group.'
      ];
  const link =
    error?.errorLink && error?.errorLinkText
      ? { title: error.errorLinkText, url: error.errorLink }
      : undefined;

  return <BaseError title={title} paragraphs={paragraphs} link={link} />;
};
