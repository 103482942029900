import { TabsTrigger } from '../../ui/tabs';

export const StyledTabsTrigger = ({
  value,
  children
}: {
  value: string;
  children: React.ReactNode;
}) => (
  <TabsTrigger
    value={value}
    className="flex-grow text-base leading-6 font-semibold border-b-4
      data-[state=active]:text-[#C3D82E] hover:border-b-4 hover:text-[#C3D82E] border-[#303030]
      data-[state=active]:border-[#C3D82E] px-6 pb-4 rounded-none"
  >
    {children}
  </TabsTrigger>
);
