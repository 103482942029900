import {
  Scene,
  Group,
  Object3DEventMap,
  PerspectiveCamera as ThreePerspectiveCamera,
  MathUtils
} from 'three';
import { useMemo, useRef } from 'react';
import { useFrame, useThree, createPortal } from '@react-three/fiber';
import { PerspectiveCamera, CameraControls } from '@react-three/drei';
import { SubjectContext } from './SubjectContext';
import { ReferenceContext } from './ReferenceContext';
import { Circle } from './Circle';
import { Context3DConfig } from '#server/models';
import { SubjectViewState } from '../Subject';

export const Context = function ({
  referenceModelPath,
  subjectContextModelPath,
  contextConfig,
  cameraControlRef,
  subjectViewState: { contextOn, contextSize }
}: {
  referenceModelPath: string;
  subjectContextModelPath: string;
  contextConfig: Context3DConfig;
  cameraControlRef: React.MutableRefObject<CameraControls | null>;
  subjectViewState: SubjectViewState;
}) {
  const { gl, scene, camera, size } = useThree();
  const contextScene = useMemo(() => new Scene(), []);
  const contextCamera = useRef<ThreePerspectiveCamera | null>(null);
  const contextGroup = useRef<Group<Object3DEventMap> | null>(null);
  const initialRotationX = MathUtils.degToRad(contextConfig.fullContext.rotation[0]);
  const initialRotationY = MathUtils.degToRad(contextConfig.fullContext.rotation[1]);
  const initialRotationZ = MathUtils.degToRad(contextConfig.fullContext.rotation[2]);

  useFrame(() => {
    if (contextCamera.current) {
      // Render the main viewport
      gl.clear();
      gl.setViewport(0, 0, size.width, size.height);
      gl.setScissor(0, 0, size.width, size.height);
      gl.setScissorTest(true);

      gl.render(scene, camera);

      // Make sure aspect ration of context camera is always 1
      // It can get updated by the main ratio
      contextCamera.current.aspect = 1;
      contextCamera.current.updateProjectionMatrix();

      // Setup and render context viewport
      gl.clearDepth();
      gl.setViewport(window.innerWidth - contextSize, 0, contextSize, contextSize);
      gl.setScissor(window.innerWidth - contextSize, 0, contextSize, contextSize);
      gl.setScissorTest(true);
      gl.render(contextScene, contextCamera.current);
    }

    // Update context rotation to match main camera angels
    if (contextGroup.current && cameraControlRef.current) {
      contextGroup.current.rotation.z = initialRotationZ + cameraControlRef.current.azimuthAngle;
      contextGroup.current.rotation.x =
        initialRotationX + Math.PI - cameraControlRef.current.polarAngle;
    }
  }, 1);

  return createPortal(
    <>
      <PerspectiveCamera
        ref={contextCamera}
        fov={30}
        aspect={1}
        near={0.1}
        far={40}
        makeDefault={false}
        position-z={15}
      />
      <ambientLight color={0xffffff} intensity={1} />
      {contextOn ? (
        <group ref={contextGroup} rotation={[initialRotationX, initialRotationY, initialRotationZ]}>
          <group>
            <Circle radius={3} />
            <Circle radius={3} rotation={[0, Math.PI / 2, 0]} />
            <Circle radius={3} rotation={[Math.PI / 2, 0, 0]} />
            <ReferenceContext
              modelPath={referenceModelPath}
              config={contextConfig.referenceContext}
            />
          </group>
          {contextConfig.subjectContext ? (
            <SubjectContext
              modelPath={subjectContextModelPath}
              config={contextConfig.subjectContext}
            />
          ) : null}
        </group>
      ) : null}
    </>,
    contextScene
  );
};
