import { CrossIcon } from '../Common';
import { SheetClose } from './sheet';

export const SheetCloseButton = () => {
  return (
    <SheetClose className="absolute right-4 top-4 hover:stroke-[#E8E8E8]" aria-label="Close">
      <CrossIcon className="w-5 h-5" />
    </SheetClose>
  );
};
