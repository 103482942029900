import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const SignIn = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const restorePathname = localStorage.getItem('signin-restore-pathname');
    console.log('restore path name:', restorePathname);

    if (restorePathname) {
      localStorage.removeItem('signin-restore-pathname');
      navigate(restorePathname);
    } else {
      navigate('/');
    }
  }, []);

  return <></>;
};
