import { AwsRum, AwsRumConfig } from 'aws-rum-web';

export type RumClientConfig = {
  appMonitorId: string;
  identityPoolId: string;
  guestRoleArn: string;
  sessionSampleRate: number;
  region: string;
  apiUri: string;
};

const regexPatterForUri = (uri: string): RegExp => {
  return new RegExp(`^${uri.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')}.*$`);
};

export const RumClient = {
  initialise: ({
    appMonitorId,
    identityPoolId,
    guestRoleArn,
    sessionSampleRate = 1,
    region = 'ap-southeast-2',
    apiUri
  }: RumClientConfig): AwsRum => {
    const rumConfig: AwsRumConfig = {
      allowCookies: true,
      endpoint: 'https://dataplane.rum.ap-southeast-2.amazonaws.com',
      guestRoleArn: guestRoleArn,
      identityPoolId: identityPoolId,
      sessionSampleRate,
      telemetries: [
        'performance',
        'errors',
        ['http', { addXRayTraceIdHeader: [regexPatterForUri(apiUri)] }]
      ],
      enableXRay: true
    };

    return new AwsRum(appMonitorId, '1.0.0', region, rumConfig);
  }
};
