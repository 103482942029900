// Custom version of useElementSize to fix bug as found
// here https://github.com/juliencrn/usehooks-ts/issues/236

import { RefObject, useRef, useState } from 'react';
import { useIsomorphicLayoutEffect } from 'usehooks-ts';

interface Size {
  width: number;
  height: number;
}
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function useElementSize<T extends HTMLElement = HTMLDivElement>(): [
  RefObject<T>,
  Size,
  (element: Element | null) => void
] {
  const ref = useRef<T>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0
  });

  const updateSize = (element: Element | null) => {
    const { width, height } = element?.getBoundingClientRect() ?? {
      width: 0,
      height: 0
    };
    setSize({ width, height });
  };

  useIsomorphicLayoutEffect(() => {
    updateSize(ref.current);

    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      if (entry) {
        updateSize(entry.target);
      }
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, [ref.current]);

  return [ref, size, updateSize];
}
