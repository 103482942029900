import { ChangeEventHandler, useEffect, useState } from 'react';
import {
  SearchLabelState,
  SearchResults,
  Sheet,
  SheetCloseButton,
  SheetContent
} from '@/components';
import { LabelsView } from './LabelsView';
import { SubjectDetailsView } from './SubjectDetailsView';
import { SearchSubjectsState } from './SearchFilterDropDown';
import { useParams } from 'react-router-dom';
import { useAreaConfig, useCurrentUserEnrolments, useElementSize, useSubjectConfig } from '@/hooks';
import { SearchSubjectsView } from './SearchSubjectsView';
import { Discipline, LabelConfig, ViewerType } from '#server/models';
import { SubjectViewState } from '../ThreeDeeViewer/Experience/Subject';
import { useWindowSize } from 'usehooks-ts';
import { ScreenSize } from '../Common/Device';

export type SideMenuViews = 'Search' | 'Labels' | 'Details';

export interface SideMenuProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  view: SideMenuViews;
  topOffset: number;
  subjectViewState: SubjectViewState;
  setSubjectViewState: (subjectViewState: SubjectViewState) => void;
  searchLabelsState: SearchLabelState;
  setSearchLabelsState: (searchLabelsState: SearchLabelState) => void;
  onChangeLabelSearchFilter: ChangeEventHandler<HTMLInputElement> | undefined;
  getUpdatedLabels: () => LabelConfig[] | undefined;
}

export const SideMenu = ({
  open,
  setOpen,
  view = 'Search',
  topOffset,
  subjectViewState,
  setSubjectViewState,
  searchLabelsState,
  setSearchLabelsState,
  onChangeLabelSearchFilter,
  getUpdatedLabels
}: SideMenuProps) => {
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const isMobile = windowWidth <= ScreenSize.mobile;
  const search_results_width_ratio = isMobile ? 100 : 70;
  const { viewerType, subjectSlug, areaSlug } = useParams();
  const { data: modelConfig } = useSubjectConfig(
    subjectSlug,
    viewerType as ViewerType | undefined,
    areaSlug
  );

  const { data: areaConfig } = useAreaConfig(areaSlug);
  const { data: unitsCollection } = useCurrentUserEnrolments();
  const [searchResultOpen, setSearchResultOpen] = useState(false);
  const [menuRef, { width: menuWidth }] = useElementSize();
  const [currentViewRef, { height: currentViewHeight }, updateSize] = useElementSize();

  const MOBILE_BOTTOM_GAP = 30;
  let topPosition = `${topOffset}px`;
  if (isMobile && windowHeight - topOffset > currentViewHeight) {
    topPosition = `${windowHeight - currentViewHeight - MOBILE_BOTTOM_GAP}px`;
  }

  const [searchTerms, setSearchTerms] = useState<string | undefined>(undefined);
  const [searchResultsHeight, setSearchResultsHeight] = useState(() => windowHeight);
  const [searchResultsWidth, setSearchResultsWidth] = useState(() => windowWidth);
  const [, ...nonPrimaryCategoryGroups] =
    areaConfig?.categoryGroups.toSorted((a, b) => (a.isPrimary ? -1 : 1)) ?? [];

  const [searchSubjectsState, setSearchSubjectsState] = useState<SearchSubjectsState>({
    unitsFilter: [],
    systemsFilter: [],
    regionsFilter: [],
    disciplinesFilter: [],
    modelTypesFilter: [],
    resultTypesFilter: []
  });
  const [searchLabelState, setSearchLabelState] = useState<SearchLabelState>({
    labelSearchFilter: undefined,
    structuresFilter: [],
    unitsFilter: []
  });

  useEffect(() => {
    if (open) {
      updateSize(currentViewRef.current);
    }
  }, [open]);

  useEffect(() => {
    setSearchResultsHeight(() => windowHeight);
    setSearchResultsWidth(() => (windowWidth * search_results_width_ratio) / 100);
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    const userEnrolments = (unitsCollection ?? []).map((unit) => unit.udc);
    const units = areaConfig?.units.sort((a, b) => a.udc.localeCompare(b.udc)) ?? [];
    const defaultUnitFilterList = units.filter((unit) => userEnrolments.includes(unit.udc));
    setSearchSubjectsState({
      ...searchSubjectsState,
      ['unitsFilter']: defaultUnitFilterList
    });
  }, [unitsCollection, areaConfig]);

  useEffect(() => {
    // Open side menu if we are on an area page but not the 3D Viewer page
    if (!isMobile && areaConfig && !subjectSlug) {
      setOpen(true);
    }
  }, [areaConfig]);

  const onInteractOutsideSearchSubjects = (e: any) => {
    // Don't close the side menu if the user clicks outside of the searchsubjects sheet
    e.preventDefault();
  };

  const onInteractOutsideSearchResults = (e: any) => {
    // Close the side menu if the user clicks outside of the searchresults sheet unless it is in the searchsubjects sheet
    const boundingRect = menuRef.current?.getBoundingClientRect();
    if (!boundingRect) return;
    const { left, top, right, bottom } = boundingRect;
    if (e.detail.originalEvent instanceof PointerEvent) {
      const { clientX, clientY } = e.detail.originalEvent;
      if (clientX <= right && clientX >= left && clientY <= bottom && clientY >= top) {
        e.preventDefault();
      }
    }
    if (e.detail.originalEvent instanceof FocusEvent) {
      e.preventDefault();
    }
  };

  const setSearchSubjects = (terms: string | undefined) => {
    setSearchTerms(terms);
    setSearchResultOpen(true);
  };

  let currentView: React.ReactNode;

  switch (view) {
    case 'Search':
      currentView = (
        <SearchSubjectsView
          searchSubjectsState={searchSubjectsState}
          setSearchSubjectsState={setSearchSubjectsState}
          areaConfig={areaConfig}
          setSearchSubjects={setSearchSubjects}
          categories={nonPrimaryCategoryGroups}
        />
      );
      break;
    case 'Labels':
      currentView = (
        <LabelsView
          searchLabelsState={searchLabelsState}
          setSearchLabelsState={setSearchLabelsState}
          onChangeLabelSearchFilter={onChangeLabelSearchFilter}
          getUpdatedLabels={getUpdatedLabels}
          modelConfig={modelConfig}
          areaConfig={areaConfig}
          subjectViewState={subjectViewState}
          setSubjectViewState={setSubjectViewState}
        />
      );
      break;
    case 'Details':
      currentView = (
        <SubjectDetailsView
          areaConfig={areaConfig}
          modelConfig={modelConfig}
          categoryGroups={nonPrimaryCategoryGroups}
        />
      );
      break;
  }

  const handleOnOpenChange = (open: boolean) => {
    setOpen(open);
    if (!open) {
      setSearchTerms(undefined);
      setSearchResultOpen(false);
    }
  };

  const handleSearchResultOpenChange = (open: boolean) => {
    setSearchResultOpen(open);
    if (!open && isMobile) {
      setOpen(false);
    }
  };

  return (
    <Sheet open={open} onOpenChange={handleOnOpenChange} modal={false}>
      <SheetContent
        side={isMobile ? 'bottom' : 'left'}
        className={`${isMobile && searchResultOpen ? 'hidden' : 'block'} border-inherit ${
          isMobile ? 'w-full' : 'w-96'
        } bg-[#1A1A1A] border-[#1A1A1A] p-0 z-[200]`}
        style={{
          bottom: isMobile ? `0px` : undefined,
          top: topPosition
        }}
        showDefaultCloseButton={false}
        onInteractOutside={onInteractOutsideSearchSubjects}
        ref={menuRef}
      >
        <SheetCloseButton />
        <div className="p-4 text-[#E8E8E8]" ref={currentViewRef}>
          {currentView}
        </div>
        <Sheet open={searchResultOpen} onOpenChange={handleSearchResultOpenChange} modal={false}>
          <SheetContent
            side={isMobile ? 'bottom' : 'left'}
            className="border-[#1A1A1A] z-[100]"
            style={{
              width: `${search_results_width_ratio}%`,
              top: `${topOffset}px`,
              left: `${menuWidth}px`
            }}
            showDefaultCloseButton={false}
            onInteractOutside={onInteractOutsideSearchResults}
          >
            <SheetCloseButton />
            <SearchResults
              searchTerms={searchResultOpen ? searchTerms : undefined}
              searchSubjectsState={searchSubjectsState}
              areaSlug={areaSlug ?? ''}
              areaLabelName={areaConfig?.labelName.singular ?? ''}
              areaSubjectName={areaConfig?.subjectName.singular ?? ''}
              disciplines={areaConfig?.disciplines as Discipline[]}
              setOpenSideMenu={handleOnOpenChange}
              searchResultsHeight={searchResultsHeight}
              searchResultsWidth={searchResultsWidth}
              setSearchResultOpen={setSearchResultOpen}
            />
          </SheetContent>
        </Sheet>
      </SheetContent>
    </Sheet>
  );
};
