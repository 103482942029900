export const ChevronIcon = ({
  className,
  style,
  dataState
}: {
  className?: string;
  style?: any;
  dataState?: string;
}) => (
  <svg
    width="15"
    height="9"
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
    data-state={dataState}
  >
    <path
      id="Path"
      d="M0.295079 1.45492L1.70508 0.0449219L7.50008 5.83492L13.2951 0.0449219L14.7051 1.45492L7.50008 8.66492L0.295079 1.45492Z"
      fill="currentColor"
    />
  </svg>
);
