import { useSearchSubjects } from '@/hooks';
import { ResultItem } from './ResultItem';
import { v4 as uuidv4 } from 'uuid';
import { Discipline } from '#server/models';
import { Pagination } from '../Pagination/Pagination';
import { useEffect, useState } from 'react';
import { SearchSubjectsState } from '../SideMenu/SearchFilterDropDown';
import { ChevronIcon, Device } from '../Common';
import { useWindowSize } from 'usehooks-ts';
import { ScreenSize } from '../Common/Device';

const MAX_NO_ITEMS = 30;
class ViewSettings {
  cardWidthPlusGap: number;
  cardHeightPlusGap: number;
  paginationHeight: number;
  topGap: number;

  constructor(
    cardWidthPlusGap: number,
    cardHeightPlusGap: number,
    paginationHeight: number,
    topGap: number = 0
  ) {
    this.cardWidthPlusGap = cardWidthPlusGap;
    this.cardHeightPlusGap = cardHeightPlusGap;
    this.paginationHeight = paginationHeight;
    this.topGap = topGap;
  }
}

class NonMobileViewSettings extends ViewSettings {
  constructor() {
    super(370, 230, 150);
  }
}

class MobileViewSettings extends ViewSettings {
  constructor() {
    super(330, 160, 120, 190);
  }
}

const calculatePageSize = (
  isMobile: boolean,
  searchResultsHeight: number,
  searchResultsWidth: number,
  settings: ViewSettings
) => {
  const noCardsHorizontal = Math.floor(searchResultsWidth / settings.cardWidthPlusGap);
  const noCardsVertical = Math.floor(
    (searchResultsHeight - settings.paginationHeight) / settings.cardHeightPlusGap
  );

  return isMobile
    ? Math.max(noCardsVertical * noCardsHorizontal, MAX_NO_ITEMS)
    : Math.max(noCardsVertical * noCardsHorizontal, 1);
};

export interface SearchResultsProps {
  searchTerms: string | undefined;
  searchSubjectsState: SearchSubjectsState;
  areaSlug: string;
  areaSubjectName: string;
  areaLabelName: string;
  disciplines: Discipline[];
  setOpenSideMenu: (open: boolean) => void;
  searchResultsHeight: number;
  searchResultsWidth: number;
  setSearchResultOpen: (open: boolean) => void;
}
export const SearchResults = ({
  searchTerms,
  searchSubjectsState,
  areaSlug,
  areaSubjectName,
  areaLabelName,
  disciplines,
  setOpenSideMenu,
  searchResultsHeight,
  searchResultsWidth,
  setSearchResultOpen
}: SearchResultsProps) => {
  const desktopSettings = new NonMobileViewSettings();
  const mobileSettings = new MobileViewSettings();
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth <= ScreenSize.mobile;
  const [pageSize, setPageSize] = useState(() =>
    calculatePageSize(
      isMobile,
      searchResultsHeight,
      searchResultsWidth,
      isMobile ? mobileSettings : desktopSettings
    )
  );
  const [page, setPage] = useState(1);
  const { data: searchResults } = useSearchSubjects(
    areaSlug,
    pageSize,
    page,
    searchTerms,
    searchSubjectsState
  );
  const searchResultsWithId = searchResults?.items.map((data) => ({ ...data, id: uuidv4() }));

  useEffect(() => {
    setPage(1);
  }, [searchTerms, searchSubjectsState]);

  useEffect(() => {
    setPageSize(
      calculatePageSize(
        isMobile,
        searchResultsHeight,
        searchResultsWidth,
        isMobile ? mobileSettings : desktopSettings
      )
    );
    setPage(1);
  }, [searchResultsHeight, searchResultsWidth]);
  return (
    <div className={`${isMobile ? 'flex justify-between pt-4' : 'pt-8'} px-3`}>
      {isMobile ? (
        <div>
          <button className="pt-2" onClick={() => setSearchResultOpen(false)}>
            <ChevronIcon className="w-5 h-5 transform rotate-90 -translate-y-1.5" />
          </button>
        </div>
      ) : null}
      {searchResultsWithId?.length === 0 ? (
        <p className="font-normal text-center lg:text-lg text-sm">No results found</p>
      ) : (
        <div>
          {searchResults && (
            <div className="font-normal lg:text-lg text-sm text-center">
              Showing {searchResults?.items.length} out of {searchResults?.totalSubjects} results
            </div>
          )}
          <div
            style={{
              height: isMobile ? `${searchResultsHeight - mobileSettings.topGap}px` : 'auto'
            }}
            className={`${isMobile ? 'mt-4 overflow-auto' : 'mt-8 overflow-hidden'}`}
          >
            <div
              className="flex flex-row justify-center gap-x-5 lg:gap-x-10 gap-y-5 lg:gap-y-8
                flex-wrap mt-1"
            >
              {searchResultsWithId?.map((item) => (
                <ResultItem
                  searchResult={item}
                  areaSlug={areaSlug}
                  areaSubjectName={areaSubjectName}
                  areaLabelName={areaLabelName}
                  key={item.id}
                  disciplines={disciplines}
                  setOpenSideMenu={setOpenSideMenu}
                />
              ))}
            </div>
            <Pagination
              setPage={setPage}
              totalPages={searchResults?.totalPages}
              page={page}
            ></Pagination>
          </div>
        </div>
      )}
    </div>
  );
};
