import { DXPAnalytics } from '@curtin-dxp/web-client';
import { SubjectViewState } from '../ThreeDeeViewer/Experience/Subject';
import { Slider, Switch } from '../ui';
import { getCommonAnalyticsContext } from '@/lib/utils';

export interface SettingsViewProps {
  subjectViewState: SubjectViewState;
  setSubjectViewState: (subjectViewState: SubjectViewState) => void;
}
export const SettingsView = ({
  subjectViewState,
  subjectViewState: { labelsOn, axesOn, contextOn, labelsOnHover, labelsWithNumbers },
  setSubjectViewState
}: SettingsViewProps) => {
  return (
    <div className="mx-6 mt-8">
      <div className="flex flex-col gap-8">
        <h4 className="text-xl font-bold">Settings</h4>
        <div className="text-xl font-bold mt-2">3D Context</div>
        <div className="flex flex-col gap-8">
          <div className="flex justify-between ">
            <span>Context visible</span>
            <Switch
              checked={contextOn}
              onCheckedChange={(open: boolean) => {
                setSubjectViewState({ ...subjectViewState, contextOn: open });
                DXPAnalytics.trackAction({
                  name: 'TOGGLE_CONTEXT',
                  context: { ...getCommonAnalyticsContext(), context5: open ? 'ON' : 'OFF' }
                });
              }}
              aria-label="context visible switch"
            />
          </div>
          <div className="flex justify-between items-center space-x-3">
            <div className="flex-none">Context size</div>
            <div className="flex-auto text-right mr-3"> {subjectViewState.contextSize}</div>
            <Slider
              className="flex-auto text-right w-1/2"
              defaultValue={[subjectViewState.contextSize]}
              min={200}
              max={500}
              step={10}
              onValueChange={(value) =>
                setSubjectViewState({ ...subjectViewState, contextSize: value[0] })
              }
              onValueCommit={(value) => {
                DXPAnalytics.trackAction({
                  name: 'CHANGE_CONTEXT_SIZE',
                  context: { ...getCommonAnalyticsContext(), context5: value[0].toString() }
                });
              }}
            />
          </div>
          <div className="flex justify-between">
            <div>Axes</div>
            <Switch
              checked={axesOn}
              onCheckedChange={(open: boolean) => {
                setSubjectViewState({ ...subjectViewState, axesOn: open });
                DXPAnalytics.trackAction({
                  name: 'TOGGLE_AXES',
                  context: { ...getCommonAnalyticsContext(), context5: open ? 'ON' : 'OFF' }
                });
              }}
              aria-label="axes switch"
            />
          </div>
        </div>

        <div className="flex flex-col gap-8">
          <div className="text-xl font-bold mt-2">Labels</div>
          <div className="flex justify-between">
            <span>Labels</span>
            <Switch
              checked={labelsOn}
              onCheckedChange={(open: boolean) => {
                setSubjectViewState({ ...subjectViewState, labelsOn: open });
                DXPAnalytics.trackAction({
                  name: 'TOGGLE_LABELS',
                  context: { ...getCommonAnalyticsContext(), context5: open ? 'ON' : 'OFF' }
                });
              }}
              aria-label="labels switch"
            />
          </div>
          <div className="flex justify-between items-center space-x-3">
            <div className="flex-none">Labels size</div>
            <div className="flex-auto text-right mr-3">{subjectViewState.labelsSize}</div>
            <Slider
              className="flex-auto text-right w-1/2"
              defaultValue={[subjectViewState.labelsSize]}
              min={0.5}
              max={1.5}
              step={0.1}
              onValueChange={(value) =>
                setSubjectViewState({ ...subjectViewState, labelsSize: value[0] })
              }
              onValueCommit={(value) => {
                DXPAnalytics.trackAction({
                  name: 'CHANGE_LABEL_SIZE',
                  context: { ...getCommonAnalyticsContext(), context5: value[0].toString() }
                });
              }}
            />
          </div>
          <div className="flex justify-between">
            <span>Label text on hover</span>
            <Switch
              checked={labelsOnHover}
              onCheckedChange={(open: boolean) => {
                setSubjectViewState({ ...subjectViewState, labelsOnHover: open });
                DXPAnalytics.trackAction({
                  name: 'TOGGLE_LABEL_TEXT_ON_HOVER',
                  context: { ...getCommonAnalyticsContext(), context5: open ? 'ON' : 'OFF' }
                });
              }}
              aria-label="labels on hover switch"
            />
          </div>
          <div className="flex justify-between">
            <span>Label with Numbers</span>
            <Switch
              checked={labelsWithNumbers}
              onCheckedChange={(open: boolean) => {
                setSubjectViewState({ ...subjectViewState, labelsWithNumbers: open });
                DXPAnalytics.trackAction({
                  name: 'TOGGLE_LABEL_WITH_NUMBERS',
                  context: { ...getCommonAnalyticsContext(), context5: open ? 'ON' : 'OFF' }
                });
              }}
              aria-label="labels with numbers"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
