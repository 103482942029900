import { useRef, useEffect } from 'react';
import { CameraControls } from '@react-three/drei';
import { Subject, SubjectViewState } from './Subject';
import { Context } from './Context';
import { SubjectConfig, AreaConfig, LabelConfig } from '#server/models';
import { SearchLabelState } from '@/components';
import { NavigationControlsEventListener } from '@/components/Common/EventsHandler';

interface ExperienceProps {
  subjectModelPath: string;
  subjectTexturePath: string;
  referenceModelPath: string;
  subjectContextModelPath: string;
  modelConfig: SubjectConfig;
  areaConfig: AreaConfig;
  setSideMenuState: any;
  subjectViewState: SubjectViewState;
  setSubjectViewState: (subjectViewState: SubjectViewState) => void;
  searchLabelsState: SearchLabelState;
  getUpdatedLabels: () => LabelConfig[] | undefined;
  toggleResetView: () => void;
  handleSubjectLoadProgress: (progress: number) => void;
}
export const Experience = ({
  subjectModelPath,
  subjectTexturePath,
  referenceModelPath,
  subjectContextModelPath,
  modelConfig,
  areaConfig,
  setSideMenuState,
  subjectViewState,
  setSubjectViewState,
  searchLabelsState,
  getUpdatedLabels,
  toggleResetView,
  handleSubjectLoadProgress
}: ExperienceProps) => {
  const modelSettings = modelConfig.config3D!;
  const cameraControlsRef = useRef<CameraControls | null>(null);
  const { reset } = subjectViewState;

  useEffect(() => {
    if (cameraControlsRef.current) {
      // https://github.com/yomotsu/camera-controls/blob/ce352346583bc4ad4666fcff32b74e84bc7bc513/src/types.ts#L33.
      // We use these numbers because the constants are not exposed in DREI.
      // '4' = CameraControls.ACTION.OFFSET
      // '2048' = CameraControls.ACTION.TOUCH_DOLLY_OFFSET
      // '128' = CameraControls.ACTION.TOUCH_OFFSET
      cameraControlsRef.current.mouseButtons.right = 4;
      cameraControlsRef.current.touches.two = 2048;
      cameraControlsRef.current.touches.three = 128;
    }
  }, [cameraControlsRef.current]);

  useEffect(() => {
    if (reset) {
      cameraControlsRef?.current?.reset(true);
      cameraControlsRef?.current?.dollyTo(modelSettings.dist, true);
      setSubjectViewState({ ...subjectViewState, reset: false });
    }
  }, [reset]);

  return (
    <>
      <CameraControls
        makeDefault
        ref={cameraControlsRef}
        draggingSmoothTime={0.3}
        distance={modelSettings.dist}
        minDistance={modelSettings.distMin}
        maxDistance={modelSettings.distMax}
      />
      <NavigationControlsEventListener
        resetViewHandler={toggleResetView}
        cameraControlsRef={cameraControlsRef}
      />
      <Subject
        modelPath={subjectModelPath}
        texturePath={subjectTexturePath}
        modelConfig={modelConfig}
        areaConfig={areaConfig}
        cameraControlsRef={cameraControlsRef}
        setSideMenuState={setSideMenuState}
        subjectViewState={subjectViewState}
        setSubjectViewState={setSubjectViewState}
        searchLabelsState={searchLabelsState}
        getUpdatedLabels={getUpdatedLabels}
        handleSubjectLoadProgress={handleSubjectLoadProgress}
      />
      <Context
        referenceModelPath={referenceModelPath}
        subjectContextModelPath={subjectContextModelPath}
        contextConfig={modelSettings.context}
        cameraControlRef={cameraControlsRef}
        subjectViewState={subjectViewState}
      />
    </>
  );
};
