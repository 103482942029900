import { useState } from 'react';
import { useElementSize } from '@/hooks';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  ChevronIcon,
  SearchLabelState
} from '@/components';
import { Category, Discipline, Unit, ViewerType } from '#server/models';
import { DXPAnalytics } from '@curtin-dxp/web-client';
import { getCommonAnalyticsContext } from '@/lib/utils';

export interface ModelType {
  id: ViewerType;
  name: string;
}

export interface ResultType {
  id: 'subject' | 'label';
  name: string;
}

export interface SearchSubjectsState {
  unitsFilter: Unit[];
  systemsFilter: Category[];
  regionsFilter: Category[];
  disciplinesFilter: Discipline[];
  modelTypesFilter: ModelType[];
  resultTypesFilter: ResultType[];
}

export const SearchFilterDropDown = <
  S extends SearchLabelState | SearchSubjectsState,
  T extends Category | Unit | Discipline | ModelType | ResultType,
  OptionDisplay extends keyof T
>({
  state,
  setState,
  stateFilterFieldName,
  stateFilterValues,
  fullOptionList,
  optionDisplayKey,
  dropDownName
}: {
  state: S;
  setState: (state: S) => void;
  stateFilterFieldName: string;
  stateFilterValues: T[];
  fullOptionList: T[];
  optionDisplayKey: OptionDisplay;
  dropDownName: string;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [triggerRef, { width: triggerWidth }] = useElementSize<HTMLButtonElement>();

  return (
    <DropdownMenu open={open}>
      <DropdownMenuTrigger asChild className="mt-4" ref={triggerRef}>
        <button
          className="flex h-10 w-full rounded-md border border-input px-5 py-2.5 text-base
            ring-offset-background focus-visible:outline-none focus-visible:ring-2
            focus-visible:ring-offset-2 bg-[#303030] border-none text-[#F0F0F0]
            data-[state=active]:bg-[#B3B3B3] data-[state=active]:text-[#1A1A1A] justify-between
            items-center hover:bg-[#4B4B4B] data-[state=active]:hover:bg-[#B3B3B3]"
          style={{ backgroundColor: open && !stateFilterValues.length ? '#4B4B4B' : undefined }}
          data-state={stateFilterValues.length ? 'active' : 'inactive'}
          onClick={() => setOpen(!open)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setOpen(!open);
            }
          }}
        >
          <div className="first-letter:uppercase text-left">
            {stateFilterValues.length
              ? `${dropDownName} (${stateFilterValues.length})`
              : dropDownName}
          </div>
          <ChevronIcon
            className="h-4 w-4 stroke-[#F0F0F0] fill-[#F0F0F0] data-[state=active]:stroke-[#1A1A1A]
              data-[state=active]:fill-[#1A1A1A] transition-transform duration-300"
            style={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)' }}
            dataState={stateFilterValues.length ? 'active' : 'inactive'}
          />
        </button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={`bg-[#303030] border-none z-[300] max-h-[calc(40vh)] overflow-y-auto
          scrollbar-w-4 scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar
          scrollbar-track-[#303030] scrollbar-thumb-[#717171]`}
        style={{ width: `${triggerWidth}px` }}
        onInteractOutside={() => setOpen(false)}
        onEscapeKeyDown={() => setOpen(false)}
      >
        <DropdownMenuCheckboxItem
          className="focus:bg-[#B3B3B3]"
          checked={stateFilterValues.length === 0}
          onCheckedChange={(checked) => {
            if (checked) {
              setState({ ...state, [stateFilterFieldName]: [] });
            }
            DXPAnalytics.trackAction({
              name: 'SEARCH_FILTER_CHANGED',
              context: {
                ...getCommonAnalyticsContext(),
                context5: dropDownName,
                context6: 'SHOW_ALL'
              }
            });
          }}
        >
          Show all
        </DropdownMenuCheckboxItem>
        {fullOptionList.map((option) => (
          <DropdownMenuCheckboxItem
            key={`${stateFilterFieldName}-${option.id}`}
            className="focus:bg-[#B3B3B3]"
            checked={stateFilterValues.some((sf) => sf.id === option.id)}
            onCheckedChange={(checked) => {
              let newFilterList = stateFilterValues;
              if (checked) {
                newFilterList.push(option);
              } else {
                newFilterList = newFilterList.filter((o) => o.id !== option.id);
              }
              setState({ ...state, [stateFilterFieldName]: newFilterList });
              DXPAnalytics.trackAction({
                name: 'SEARCH_FILTER_CHANGED',
                context: {
                  ...getCommonAnalyticsContext(),
                  context5: dropDownName,
                  context6: checked ? 'ADD' : 'REMOVE',
                  context7: option.name
                }
              });
            }}
          >
            <div className="first-letter:uppercase">{option[optionDisplayKey] as string}</div>
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
