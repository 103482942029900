import { BaseError } from '../BaseError';

export const ServerError = () => {
  return (
    <BaseError
      title={'Server Error'}
      paragraphs={['The server encountered an error. Please try again later.']}
    />
  );
};
