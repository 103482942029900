const size = {
  'screen-xs': 1024
};

export type DeviceType = 'mobile';

export const Device: { [key in DeviceType]: string } = {
  mobile: `(not (min-width: ${size['screen-xs']}px))`
};

export const ScreenSize: { [key in DeviceType]: number } = {
  mobile: size['screen-xs']
};
