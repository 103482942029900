export const KeyboardOrbitIcon = () => (
  <svg width="127" height="66" viewBox="0 0 127 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="37" height="28" rx="5" stroke="#E8E8E8" stroke-width="2" />
    <path
      d="M20.086 19H18.756L17.93 16.928H13.282L12.456 19H11.126L14.878 9.662H16.32L20.086 19ZM17.58 15.892L15.606 10.866L13.618 15.892H17.58ZM22.3148 19H21.2648V9.662H22.3148V19ZM26.1174 19.168C25.1234 19.168 24.6194 18.594 24.6194 17.586V13.162H23.4994V12.238H24.6194V10.39H25.6694V12.238H27.0414V13.162H25.6694V17.362C25.6694 17.866 25.8934 18.23 26.3554 18.23C26.6494 18.23 26.9294 18.104 27.0694 17.95L27.3774 18.734C27.1114 18.986 26.7334 19.168 26.1174 19.168Z"
      fill="white"
    />
    <path
      d="M53.55 14.636H50.328V18.29H48.762V14.636H45.522V13.214H48.762V9.704H50.328V13.214H53.55V14.636Z"
      fill="white"
    />
    <rect x="62" y="1" width="28" height="28" rx="5" stroke="#E8E8E8" stroke-width="2" />
    <path
      d="M82 18L76 12L70 18"
      stroke="#E8E8E8"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect x="98" y="1" width="28" height="28" rx="5" stroke="#E8E8E8" stroke-width="2" />
    <path
      d="M106 12L112 18L118 12"
      stroke="#E8E8E8"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect x="62" y="37" width="28" height="28" rx="5" stroke="#E8E8E8" stroke-width="2" />
    <path
      d="M79 45L73 51L79 57"
      stroke="#E8E8E8"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect x="98" y="37" width="28" height="28" rx="5" stroke="#E8E8E8" stroke-width="2" />
    <path
      d="M109 57L115 51L109 45"
      stroke="#E8E8E8"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
