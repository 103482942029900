import { Canvas } from '@react-three/fiber';
import { Suspense } from 'react';
import { NoToneMapping } from 'three';
import { Experience } from './Experience';
import { useParams } from 'react-router-dom';
import { useAreaConfig } from '@/hooks';
import { AccessDenied, Loader, NotFound, ServerError } from '../Common';

export const AreaLanding = () => {
  const { areaSlug } = useParams();
  const { data: areaConfig, error } = useAreaConfig(areaSlug);

  if (error) {
    switch (error.data?.code) {
      case 'FORBIDDEN':
        return <AccessDenied error={error.data.accessError as any} />;
      case 'NOT_FOUND':
        return (
          <NotFound
            message={
              'The area you were trying to view could not be found. Please check the link provided.'
            }
          />
        );
      default:
        return <ServerError />;
    }
  }

  return (
    <Canvas
      gl={{
        precision: 'highp',
        toneMapping: NoToneMapping,
        pixelRatio: window.devicePixelRatio,
        autoClear: false
      }}
    >
      {areaConfig?.landingDemo ? (
        <Suspense fallback={<Loader />}>
          <Experience areaConfig={areaConfig} />
        </Suspense>
      ) : (
        <Loader />
      )}
    </Canvas>
  );
};
