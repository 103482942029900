import {
  Object3D,
  TextureLoader,
  Mesh,
  MeshLambertMaterial,
  DoubleSide,
  SRGBColorSpace,
  MathUtils,
  Vector3,
  Raycaster
} from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { useEffect, useRef, useState } from 'react';
import { Bvh, CameraControls } from '@react-three/drei';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SearchLabelState, SideMenuViews } from '@/components';
import { LabelModel, Label } from './Label';
import '../../style.css';
import { AxesLabel } from './AxesLabel';
import { SubjectConfig, AreaConfig, LabelConfig } from '#server/models';
import { useDeviceType, useLoaderWithSignedCache } from '@/hooks';
import { useThree } from '@react-three/fiber';
import { calculateDistanceMultiplier, getCommonAnalyticsContext } from '@/lib/utils';
import { DXPAnalytics } from '@curtin-dxp/web-client';

export interface SubjectViewState {
  reset: boolean;
  labelsOn: boolean;
  labelsSize: number;
  axesOn: boolean;
  contextOn: boolean;
  labelsOnHover: boolean;
  labelsWithNumbers: boolean;
  contextSize: number;
}

export interface SubjectProps {
  modelPath: string;
  texturePath: string;
  modelConfig: SubjectConfig;
  areaConfig: AreaConfig;
  cameraControlsRef: React.MutableRefObject<CameraControls | null>;
  setSideMenuState: any;
  subjectViewState: SubjectViewState;
  setSubjectViewState: (subjectViewState: SubjectViewState) => void;
  searchLabelsState: SearchLabelState;
  getUpdatedLabels: () => LabelConfig[] | undefined;
  handleSubjectLoadProgress: (progress: number) => void;
}

export const Subject = ({
  modelPath,
  texturePath,
  modelConfig,
  areaConfig,
  cameraControlsRef,
  setSideMenuState,
  subjectViewState,
  subjectViewState: { labelsOn, labelsSize, axesOn, labelsOnHover, labelsWithNumbers },
  setSubjectViewState,
  searchLabelsState,
  getUpdatedLabels,
  handleSubjectLoadProgress
}: SubjectProps) => {
  const modelSettings = modelConfig.config3D!;
  const [searchParams, setSearchParams] = useSearchParams();
  const [labelsConfig, setLabelsConfig] = useState<LabelModel[]>();
  const subjectRef = useRef<Object3D>(null);
  const subjectModel = useLoaderWithSignedCache(FBXLoader, modelPath, undefined, (xhr) => {
    handleSubjectLoadProgress((xhr.loaded / xhr.total) * 100);
  });

  const texture = useLoaderWithSignedCache(TextureLoader, texturePath);
  texture.colorSpace = SRGBColorSpace;
  const { isMobile } = useDeviceType();
  const { camera, size } = useThree();
  const distanceMultiplier = calculateDistanceMultiplier(size.width);
  const raycaster = new Raycaster();
  const gapWithIntersectionPoint = -1;
  const initialiseLabelsConfig = () => {
    setLabelsConfig(
      labels?.map((item, index) => {
        return {
          ...item,
          isLocked: false,
          index: index,
          color: areaConfig.categoryGroups
            .find((gp) => gp.id === item.primaryCategoryId.groupId)
            ?.categories.find((c) => c.id === item.primaryCategoryId.id)?.color!
        };
      })
    );
  };
  subjectModel.traverse((child) => {
    if (child.type === 'Mesh') {
      const mesh = child as Mesh;
      mesh.material = new MeshLambertMaterial({
        side: DoubleSide,
        map: texture
      });
    }
  });
  const location = useLocation();
  const labels = getUpdatedLabels();

  useEffect(() => {
    initialiseLabelsConfig();
    if (!searchParams.has('selectedLabel')) {
      if (!isMobile) {
        setSideMenuView('Details');
      }
      setSubjectViewState({ ...subjectViewState, reset: true });
    }
  }, [modelConfig.slug, location?.state?.key]);

  useEffect(() => {
    initialiseLabelsConfig();
  }, [searchLabelsState]);

  const updateLocking = (index: number) => {
    const selectedLabelId = searchParams.get('selectedLabel');
    setLabelsConfig((lc) =>
      lc?.map((label) => {
        if (label.index === index && label.slug === selectedLabelId && label.isLocked) {
          searchParams.delete('selectedLabel');
          setSearchParams(searchParams);
        }

        return label.index !== index
          ? { ...label, isLocked: false }
          : { ...label, isLocked: !label.isLocked };
      })
    );
  };

  const setSideMenuView = (view: SideMenuViews) => {
    setSideMenuState({ open: true, view: view });
  };

  const resetLocking = () => {
    if (labelsConfig?.some((l) => l.isLocked)) {
      setLabelsConfig((lc) =>
        lc?.map((label) => {
          return { ...label, isLocked: false };
        })
      );
    }
  };

  const viewLabel = (label: LabelModel, enableTransition: boolean = true) => {
    const cameraControls = cameraControlsRef.current;
    if (!cameraControls) return;
    cameraControls.dollyTo(label.config3D!.camera.distance * distanceMultiplier, enableTransition);
    cameraControls.setPosition(
      label.config3D!.camera.position[0] * distanceMultiplier,
      label.config3D!.camera.position[1] * distanceMultiplier,
      label.config3D!.camera.position[2] * distanceMultiplier,
      enableTransition
    );
    cameraControls.setFocalOffset(
      label.config3D!.camera.focalOffset[0],
      label.config3D!.camera.focalOffset[1],
      label.config3D!.camera.focalOffset[2],
      enableTransition
    );
    cameraControls.rotateTo(
      label.config3D!.camera.rotation.azimuthAngle,
      label.config3D!.camera.rotation.polarAngle,
      enableTransition
    );
  };

  const selectLabel = (label: LabelModel) => {
    searchParams.set('selectedLabel', label.slug);
    setSearchParams(searchParams);
    updateLocking(label.index);
    viewLabel(label);
    if (!isMobile) {
      setSideMenuView('Labels');
    }
  };

  useEffect(() => {
    const selectedLabelId = searchParams.get('selectedLabel');
    const selectedLabel = labelsConfig?.find((item) => item.slug === selectedLabelId);
    if (selectedLabel && !selectedLabel.isLocked) {
      selectLabel(selectedLabel);
    }
    if (!selectedLabelId) {
      resetLocking();
    }
  }, [labelsConfig, searchParams]);

  const handleOnClick = (event: any) => {
    if (!searchParams.has('raycast')) return;
    const worldPosition = new Vector3();
    worldPosition.copy(event.point);

    const directionFromCamera = new Vector3();
    directionFromCamera.subVectors(worldPosition, camera.position);
    directionFromCamera.normalize();

    raycaster.set(camera.position, directionFromCamera);
    const newWorldPosition = worldPosition
      .clone()
      .add(raycaster.ray.direction.clone().multiplyScalar(gapWithIntersectionPoint));

    const newLocalPosition = new Vector3();
    newLocalPosition.copy(newWorldPosition);
    subjectRef.current?.worldToLocal(newLocalPosition);

    const offsetPosition = new Vector3();
    cameraControlsRef.current?.getFocalOffset(offsetPosition);

    const cameraPosition = cameraControlsRef.current?.getPosition(new Vector3());
    const positionInfo = `positionX: ${newLocalPosition.x}, positionY: ${newLocalPosition.y}, positionZ: ${newLocalPosition.z}, cameraRotationPolarAngle:${cameraControlsRef.current?.polarAngle}, cameraRotationAzmuthAngle: ${cameraControlsRef.current?.azimuthAngle}, cameraPositionX: ${cameraPosition?.x}, cameraPositionY: ${cameraPosition?.y}, cameraPositionZ: ${cameraPosition?.z}, cameraDistance: ${cameraControlsRef.current?.distance}, cameraFocalOffsetX: ${offsetPosition.x}, cameraFocalOffsetY: ${offsetPosition.y}`;
    prompt('Raycast and Camera Position:', positionInfo);
  };

  return (
    <>
      <Bvh>
        <group
          rotation={[
            modelSettings ? MathUtils.degToRad(modelSettings.rotation[0]) : 0,
            modelSettings ? MathUtils.degToRad(modelSettings.rotation[1]) : 0,
            modelSettings ? MathUtils.degToRad(modelSettings.rotation[2]) : 0
          ]}
          position={modelConfig ? modelSettings.position : [0, 0, 0]}
          scale={modelConfig ? modelSettings.scale : [1, 1, 1]}
        >
          <ambientLight color={0xffffff} intensity={modelSettings.ambientLight} />
          <primitive object={subjectModel} ref={subjectRef} onClick={handleOnClick} />
          {labelsOn && labelsConfig
            ? labelsConfig.map((label: LabelModel) => {
                return (
                  <Label
                    key={label.index}
                    label={label}
                    subjectRef={subjectRef}
                    selectLabel={selectLabel}
                    labelsSize={labelsSize}
                    labelsOnHover={labelsOnHover}
                    labelsWithNumbers={labelsWithNumbers}
                  />
                );
              })
            : null}
        </group>
      </Bvh>

      {axesOn && (
        <AxesLabel cameraControlsRef={cameraControlsRef} axesLabels={modelSettings.axesLabels} />
      )}
    </>
  );
};
