export const authConfig = {
  identityPoolId: import.meta.env.VITE_AUTH_IDENTITY_POOL_ID as string,
  identityPoolIdpName: import.meta.env.VITE_AUTH_IDENTITY_POOL_IDP_NAME as string,
  identityPoolRegion: import.meta.env.VITE_AUTH_IDENTITY_POOL_REGION as string,
  unauthRoleArn: import.meta.env.VITE_AUTH_UNAUTH_ROLE_ARN as string,
  authority: import.meta.env.VITE_AUTH_AUTHORITY as string,
  clientId: import.meta.env.VITE_AUTH_CLIENT_ID as string,
  scopes: import.meta.env.VITE_AUTH_SCOPES as string,
  redirectUri: import.meta.env.VITE_AUTH_REDIRECT_URI as string
};

export const apiConfig = {
  apiBaseUri: import.meta.env.VITE_AUTH_API_BASE_URI as string | undefined
};

export const rumConfig = {
  appMonitorId: import.meta.env.VITE_RUM_APP_MONITOR_ID as string | undefined
};

export const gmtConfig = {
  enabled: import.meta.env.VITE_GTM_ENABLED && import.meta.env.VITE_GTM_ENABLED === 'true',
  config: {
    gtmId: 'GTM-WKWRXD',
    dataLayerName: 'dataCoreLayer'
  }
};

export const featuresConfig = {
  showUserProfile:
    import.meta.env.VITE_FEATURES_SHOW_USER_PROFILE &&
    import.meta.env.VITE_FEATURES_SHOW_USER_PROFILE === 'false'
};

export const DXPConfig = {
  identityPoolId: import.meta.env.VITE_DXP_IDENTITY_POOL_ID!,
  dxpApi: {
    endpoint: import.meta.env.VITE_DXP_API_ENDPOINT!,
    apiKey: import.meta.env.VITE_DXP_API_KEY!
  },
  analytics: {
    disabled:
      !import.meta.env.VITE_DXP_ANALYTICS_DISABLED ||
      import.meta.env.VITE_DXP_ANALYTICS_DISABLED === 'true',
    producerName: 'Curtin3DViewer'
  }
};
