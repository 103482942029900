// Majority of logic from https://github.com/pmndrs/react-three-fiber/blob/master/packages/fiber/src/core/hooks.tsx
// The addition is the logic for caching signed urls

import * as THREE from 'three';
import { suspend, preload, clear } from 'suspend-react';
import { ObjectMap, buildGraph } from '@react-three/fiber';
import { SignedUrlCache } from '@/services/signed-url-cache';

type EquConfig = {
  /** Compare arrays by reference equality a === b (default), or by shallow equality */
  arrays?: 'reference' | 'shallow';
  /** Compare objects by reference equality a === b (default), or by shallow equality */
  objects?: 'reference' | 'shallow';
  /** If true the keys in both a and b must match 1:1 (default), if false a's keys must intersect b's */
  strict?: boolean;
};

const is = {
  obj: (a: any) => a === Object(a) && !is.arr(a) && typeof a !== 'function',
  fun: (a: any): a is Function => typeof a === 'function',
  str: (a: any): a is string => typeof a === 'string',
  num: (a: any): a is number => typeof a === 'number',
  boo: (a: any): a is boolean => typeof a === 'boolean',
  und: (a: any) => a === void 0,
  arr: (a: any) => Array.isArray(a),
  equ(
    a: any,
    b: any,
    { arrays = 'shallow', objects = 'reference', strict = true }: EquConfig = {}
  ) {
    // Wrong type or one of the two undefined, doesn't match
    if (typeof a !== typeof b || !!a !== !!b) return false;
    // Atomic, just compare a against b
    if (is.str(a) || is.num(a) || is.boo(a)) return a === b;
    const isObj = is.obj(a);
    if (isObj && objects === 'reference') return a === b;
    const isArr = is.arr(a);
    if (isArr && arrays === 'reference') return a === b;
    // Array or Object, shallow compare first to see if it's a match
    if ((isArr || isObj) && a === b) return true;
    // Last resort, go through keys
    let i;
    // Check if a has all the keys of b
    for (i in a) if (!(i in b)) return false;
    // Check if values between keys match
    if (isObj && arrays === 'shallow' && objects === 'shallow') {
      for (i in strict ? b : a)
        if (!is.equ(a[i], b[i], { strict, objects: 'reference' })) return false;
    } else {
      for (i in strict ? b : a) if (a[i] !== b[i]) return false;
    }
    // If i is undefined
    if (is.und(i)) {
      // If both arrays are empty we consider them equal
      if (isArr && a.length === 0 && b.length === 0) return true;
      // If both objects are empty we consider them equal
      if (isObj && Object.keys(a).length === 0 && Object.keys(b).length === 0) return true;
      // Otherwise match them by value
      if (a !== b) return false;
    }
    return true;
  }
};

export interface Loader<T> extends THREE.Loader {
  load(
    url: string,
    onLoad?: (result: T) => void,
    onProgress?: (event: ProgressEvent) => void,
    onError?: (event: unknown) => void
  ): unknown;
  loadAsync(url: string, onProgress?: (event: ProgressEvent) => void): Promise<T>;
}

export type LoaderProto<T> = new (...args: any) => Loader<T extends unknown ? any : T>;
export type LoaderReturnType<T, L extends LoaderProto<T>> = T extends unknown
  ? Awaited<ReturnType<InstanceType<L>['loadAsync']>>
  : T;
export type Extensions<T extends { prototype: LoaderProto<any> }> = (
  loader: T['prototype']
) => void;
export type ConditionalType<Child, Parent, Truthy, Falsy> = Child extends Parent ? Truthy : Falsy;
export type BranchingReturn<T, Parent, Coerced> = ConditionalType<T, Parent, Coerced, T>;

const memoizedLoaders = new WeakMap<LoaderProto<any>, Loader<any>>();

const createLoaderPromise = (
  loader: Loader<any>,
  url: string,
  baseUrlsToRetry: string[],
  onProgress?: (event: ProgressEvent<EventTarget>) => void
) =>
  new Promise((res, reject) =>
    loader.load(
      url,
      (data) => {
        if (data.scene) Object.assign(data, buildGraph(data.scene));
        res(data);
      },
      onProgress,
      (error) => {
        baseUrlsToRetry.push(url.split('?')[0]);
        return reject(new Error(`Could not load ${url}: ${(error as ErrorEvent)?.message}`));
      }
    )
  );

function loadingFn<L extends LoaderProto<any>>(
  extensions?: Extensions<L>,
  onProgress?: (event: ProgressEvent<EventTarget>) => void
) {
  return async function (Proto: L, ...baseUrls: string[]) {
    // Construct new loader and run extensions
    let loader = memoizedLoaders.get(Proto)!;
    if (!loader) {
      loader = new Proto();
      memoizedLoaders.set(Proto, loader);
    }

    if (extensions) extensions(loader);
    // Go through the urls and load them

    const baseUrlsToRetry: string[] = [];

    const responses = await Promise.allSettled(
      baseUrls.map((baseUrl) => {
        return createLoaderPromise(
          loader,
          SignedUrlCache.getPrimarySignedUrls(baseUrl),
          baseUrlsToRetry,
          onProgress
        );
      })
    );
    const successful = responses
      .filter((r) => r.status === 'fulfilled')
      .map((success: any) => Promise.resolve(success.value));
    const retries = baseUrlsToRetry.map((baseUrl) => {
      const backupSignedUrl = SignedUrlCache.promoteBackupSignedUrl(baseUrl);
      if (backupSignedUrl) {
        return createLoaderPromise(loader, backupSignedUrl, baseUrlsToRetry, onProgress);
      } else {
        return Promise.reject(new Error(`No backup for ${baseUrl}`));
      }
    });
    return await Promise.all([...successful, ...retries]);
  };
}

type GLTFLike = { scene: THREE.Object3D };

/**
 * Synchronously loads and caches assets with a three loader.
 *
 * It will store a copy of each signed url it recieved in local storage, and
 * use what it already has in the first instance in case the user
 * still has it cached in their browser. If it get's 403, it will try
 * new signed url provided.
 *
 * Note: this hook's caller must be wrapped with `React.Suspense`
 * @see https://docs.pmnd.rs/react-three-fiber/api/hooks#useloader for original code
 */
export function useLoaderWithSignedCache<
  T,
  U extends string | string[],
  L extends LoaderProto<T>,
  R = LoaderReturnType<T, L>
>(
  Proto: L,
  input: U,
  extensions?: Extensions<L>,
  onProgress?: (event: ProgressEvent<EventTarget>) => void
): U extends any[]
  ? BranchingReturn<R, GLTFLike, R & ObjectMap>[]
  : BranchingReturn<R, GLTFLike, R & ObjectMap> {
  // Use suspense to load async assets
  const signedUrls = (Array.isArray(input) ? input : [input]) as string[];
  SignedUrlCache.setSignedUrls(signedUrls);
  const baseUrls = signedUrls.map((signedUrl) => signedUrl.split('?')[0]);
  const results = suspend(loadingFn<L>(extensions, onProgress), [Proto, ...baseUrls], {
    equal: is.equ
  });
  // Return the object/s
  return (Array.isArray(input) ? results : results[0]) as U extends any[]
    ? BranchingReturn<R, GLTFLike, R & ObjectMap>[]
    : BranchingReturn<R, GLTFLike, R & ObjectMap>;
}

/**
 * Preloads an asset into cache as a side-effect.
 */
useLoaderWithSignedCache.preload = function <
  T,
  U extends string | string[],
  L extends LoaderProto<T>
>(Proto: L, input: U, extensions?: Extensions<L>) {
  const keys = (Array.isArray(input) ? input : [input]) as string[];
  return preload(loadingFn<L>(extensions), [Proto, ...keys]);
};

/**
 * Removes a loaded asset from cache.
 */
useLoaderWithSignedCache.clear = function <
  T,
  U extends string | string[],
  L extends LoaderProto<T>
>(Proto: L, input: U) {
  const keys = (Array.isArray(input) ? input : [input]) as string[];
  return clear([Proto, ...keys]);
};
