export interface SignedUrlItem {
  signedUrl: string;
  cacheTime: number;
}

interface SignedUrlStore {
  [key: string]: SignedUrlItem[];
}

const LOCAL_STORAGE_KEY = 'signedUrlCache';

const getCacheFromLocalStorage = () => {
  const existingCache = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (existingCache) {
    return JSON.parse(existingCache) as SignedUrlStore;
  } else {
    return {};
  }
};

const saveCacheToLocalStorage = (cache: SignedUrlStore) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(cache));
};

const clearExpiredCacheItems = (cache: SignedUrlStore) => {
  const now = Date.now();
  const thirtyDaysMillisecs = 1000 * 60 * 60 * 24 * 30;
  Object.keys(cache).forEach((key) => {
    const nonExpiredItems = cache[key].filter((item) => item.cacheTime + thirtyDaysMillisecs > now);
    if (nonExpiredItems.length) {
      cache[key] = nonExpiredItems;
    } else {
      delete cache[key];
    }
  });
  return cache;
};

class SignedUrlCacheClass {
  private _cache: SignedUrlStore;
  public constructor() {
    this._cache = {};

    const existingCache = getCacheFromLocalStorage();
    if (existingCache) {
      this._cache = clearExpiredCacheItems(existingCache);
    }
    saveCacheToLocalStorage(this._cache);
  }

  public setSignedUrls(signedUrls: string[]) {
    signedUrls.forEach((signedUrl) => {
      const baseUrl = signedUrl.split('?')[0];
      const existingItems = this._cache[baseUrl];
      if (existingItems) {
        this._cache[baseUrl] = existingItems.length
          ? [existingItems[0], { signedUrl, cacheTime: Date.now() }]
          : [{ signedUrl, cacheTime: Date.now() }];
      } else {
        this._cache[baseUrl] = [{ signedUrl, cacheTime: Date.now() }];
      }
    });
    saveCacheToLocalStorage(this._cache);
  }

  public getPrimarySignedUrls(baseUrl: string) {
    return this._cache[baseUrl][0].signedUrl;
  }

  public removeSignedUrls(baseUrl: string) {
    delete this._cache[baseUrl];
    saveCacheToLocalStorage(this._cache);
  }

  public promoteBackupSignedUrl(baseUrl: string) {
    const backupSignedUrl = this._cache[baseUrl][1];
    if (backupSignedUrl) {
      this._cache[baseUrl] = [backupSignedUrl];
      saveCacheToLocalStorage(this._cache);
      return backupSignedUrl.signedUrl;
    } else {
      delete this._cache[baseUrl];
      saveCacheToLocalStorage(this._cache);
      return undefined;
    }
  }
}

export const SignedUrlCache = new SignedUrlCacheClass();
