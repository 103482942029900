import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function calculateDistanceMultiplier(width: number) {
  const defaultWidth = 1920;
  const widthRatio = width / defaultWidth;
  const distanceMultiplier = 0.8 + 0.2 * (widthRatio < 1 ? 1 / widthRatio : 1);
  return distanceMultiplier;
}

export function getCurrentRouteParts() {
  const pathname = window.location.pathname;
  const urlParams = new URLSearchParams(window.location.search);
  const parts = pathname.split('/').filter((part) => part);
  return {
    areaSlug: parts[1],
    viewerType: parts[2],
    subjectSlug: parts[3],
    labelSlug: urlParams.get('selectedLabel')
  };
}

export function getCommonAnalyticsContext() {
  const { areaSlug, viewerType, subjectSlug, labelSlug } = getCurrentRouteParts();
  return {
    context1: areaSlug ?? undefined,
    context2: viewerType ?? undefined,
    context3: subjectSlug ?? undefined,
    context4: labelSlug ?? undefined
  };
}
