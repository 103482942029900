import { MeshBasicMaterial, Mesh, MathUtils } from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { Context3DConfig } from '#server/models';
import { useLoaderWithSignedCache } from '@/hooks';
export const SubjectContext = function ({
  modelPath,
  config
}: {
  modelPath: string;
  config: Context3DConfig['subjectContext'];
}) {
  const contextModel = useLoaderWithSignedCache(FBXLoader, modelPath);

  contextModel.traverse((child) => {
    if (child.type === 'Mesh') {
      const mesh = child as Mesh;
      mesh.material = new MeshBasicMaterial({
        wireframe: true,
        color: 0xffffff,
        transparent: true,
        opacity: 1
      });
    }
  });

  return (
    <primitive
      object={contextModel}
      position={config!.position}
      rotation={[
        MathUtils.degToRad(config!.rotation[0]),
        MathUtils.degToRad(config!.rotation[1]),
        MathUtils.degToRad(config!.rotation[2])
      ]}
      scale={config!.scale}
    />
  );
};
