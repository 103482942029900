import { AnnotationsState, Sheet, SheetCloseButton, SheetContent } from '@/components';
import { AnnotationControlsView } from './AnnotationControlsView';
import { SettingsView } from './SettingsView';
import { HelpView } from './HelpView';
import { ProfileView } from './ProfileView';
import { SubjectViewState } from '../ThreeDeeViewer/Experience/Subject';
import { useDeviceType } from '@/hooks';

export type ToolboxViews = 'AddAnnotation' | 'Settings' | 'Help' | 'Profile';

export interface ToolboxViewProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  view: ToolboxViews;
  topOffset: number;
  subjectViewState: SubjectViewState;
  setSubjectViewState: (subjectViewState: SubjectViewState) => void;
  annotationsState: AnnotationsState;
  setAnnotationsState: React.Dispatch<React.SetStateAction<AnnotationsState>>;
}

export const Toolbox = ({
  open,
  setOpen,
  view,
  topOffset,
  subjectViewState,
  setSubjectViewState,
  annotationsState,
  setAnnotationsState
}: ToolboxViewProps) => {
  const { isMobile } = useDeviceType();
  const onInteractOutsideToolbox = (e: any) => {
    // Don't close the side menu if the user clicks outside of the toolbox sheet
    e.preventDefault();
  };

  let currentView: React.ReactNode;

  switch (view) {
    case 'AddAnnotation':
      currentView = (
        <AnnotationControlsView
          setAnnotationsState={setAnnotationsState}
          annotationsState={annotationsState}
        />
      );
      break;
    case 'Settings':
      currentView = (
        <SettingsView
          subjectViewState={subjectViewState}
          setSubjectViewState={setSubjectViewState}
        />
      );
      break;
    case 'Help':
      currentView = <HelpView topOffset={topOffset} />;
      break;
    case 'Profile':
      currentView = <ProfileView />;
      break;
  }

  const handleOnOpenChange = (open: boolean) => {
    setOpen(open);
  };

  return (
    <Sheet open={open} onOpenChange={handleOnOpenChange} modal={false}>
      <SheetContent
        side={isMobile ? 'bottom' : 'right'}
        className="border-inherit w-23vw bg-[#1A1A1A] border-[#1A1A1A] p-0 overflow-y-hidden
          lg:overflow-y-scroll max-h-screen scrollbar-none lg:scrollbar scrollbar-track-[#1A1A1A]
          scrollbar-thumb-[#303030] scrollbar-thumb-rounded-full scrollbar-track-rounded-full
          z-[200]"
        style={{ top: `${topOffset}px` }}
        showDefaultCloseButton={false}
        onInteractOutside={onInteractOutsideToolbox}
      >
        <SheetCloseButton />
        <div className="mt-5">{currentView}</div>
      </SheetContent>
    </Sheet>
  );
};
