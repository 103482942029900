export const MagnifyingGlassIcon = ({ className, style }: { className?: string; style?: any }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <g id="ico-search-24">
      <path
        id="Icon"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0523 3.948C12.0386 1.93436 8.77387 1.93436 6.76023 3.948C4.74659 5.96165 4.74659 9.2264 6.76023 11.24C8.77387 13.2537 12.0386 13.2537 14.0523 11.24C16.0659 9.2264 16.0659 5.96165 14.0523 3.948ZM5.69957 2.88734C8.299 0.287916 12.5135 0.287916 15.1129 2.88734C17.7124 5.48677 17.7124 9.70128 15.1129 12.3007C12.6928 14.7208 8.8727 14.8878 6.25945 12.8015L2.21783 16.8431L1.15717 15.7824L5.19879 11.7408C3.11251 9.12757 3.27944 5.30748 5.69957 2.88734Z"
        fill="#E8E8E8"
      />
    </g>
  </svg>
);
