import { ChevronIcon } from '../Common';

export interface PaginationProps {
  setPage: (page: number) => void;
  totalPages?: number;
  page: number;
}

const generateValidPageNumbers = (leftMostPage: number, rightMostPage: number, max: number) => {
  const start = Math.max(leftMostPage, 1);
  const end = Math.min(rightMostPage, max);
  return Array.from({ length: end - start + 1 }, (_, index) => index + start);
};
export const Pagination = ({ setPage, totalPages, page: currentPage }: PaginationProps) => {
  if (!totalPages) return;
  let leftMostPage = currentPage - 2;
  let rightMostPage = currentPage + 2;
  const carryToRight = 1 - leftMostPage;
  const carryToLeft = rightMostPage - totalPages;

  if (carryToLeft > 0) {
    leftMostPage -= carryToLeft;
  }
  if (carryToRight > 0) {
    rightMostPage += carryToRight;
  }

  const validPageNumbers = generateValidPageNumbers(leftMostPage, rightMostPage, totalPages);

  return (
    <nav className="flex justify-center mt-7 mb-1 lg:mt-14 ">
      <div className="flex flex-row gap-3 lg:gap-8">
        {currentPage > 1 && (
          <button onClick={() => setPage(currentPage - 1)} aria-label="Goto previous page">
            <ChevronIcon className="w-4 h-4 lg:w-6 lg:h-6 transform rotate-90" />
          </button>
        )}
        {validPageNumbers[0] > 1 && (
          <span className="font-normal text-lg flex items-center justify-center">...</span>
        )}
        {validPageNumbers.map((number) => (
          <button
            key={number}
            className={`flex h-8 w-8 lg:h-10 lg:w-10 items-center justify-center text-sm lg:text-lg
            font-normal text-white ${number === currentPage ? 'bg-[#C3D82E]/20 rounded-full ' : ''}`}
            onClick={() => setPage(number)}
            tabIndex={0}
            aria-label={`Go to page ${number}`}
          >
            {number}
          </button>
        ))}
        {validPageNumbers[validPageNumbers.length - 1] < totalPages && (
          <span className="font-normal text-lg flex items-center justify-center">...</span>
        )}
        {currentPage < totalPages && (
          <button onClick={() => setPage(currentPage + 1)} aria-label="Goto next page">
            <ChevronIcon className="w-4 h-4 lg:w-6 lg:h-6 transform -rotate-90" />
          </button>
        )}
      </div>
    </nav>
  );
};
