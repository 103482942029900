export const CubeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#C3D82E" fill-opacity="0.2" />
    <g clip-path="url(#clip0_4836_36763)">
      <path
        d="M5.30316 8.13477V15.8676L12 19.7329V12L5.30316 8.13477Z"
        stroke="#C3D82E"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 19.7329L18.6969 15.8676V8.13477"
        stroke="#C3D82E"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 12.0004L18.6969 8.13517L12 4.26758L5.30316 8.13517"
        stroke="#C3D82E"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4836_36763">
        <rect width="13.928" height="16" fill="white" transform="translate(5.03601 4)" />
      </clipPath>
    </defs>
  </svg>
);
