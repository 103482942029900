import { trpc } from '@/data-loaders';
import { SearchSubjectsState } from '@/components/SideMenu/SearchFilterDropDown';

export const useSearchSubjects = (
  areaSlug = '',
  pageSize = 10,
  page = 1,
  searchTerms?: string | undefined,
  searchSubjectsState?: SearchSubjectsState | undefined
) => {
  return trpc.search.useQuery(
    {
      areaSlug,
      pageSize,
      page,
      searchTerms,
      searchFilters: {
        unitsFilter: searchSubjectsState?.unitsFilter || [],
        systemsFilter: searchSubjectsState?.systemsFilter || [],
        regionsFilter: searchSubjectsState?.regionsFilter || [],
        disciplinesFilter: searchSubjectsState?.disciplinesFilter || [],
        modelTypesFilter: searchSubjectsState?.modelTypesFilter || [],
        resultTypesFilter: searchSubjectsState?.resultTypesFilter || []
      }
    },
    {
      retry: false,
      cacheTime: 1000 * 60 * 60 * 8, // cache for 8 hours
      staleTime: 1000 * 60 * 60 * 8 // don't consider the data stale until 8 hours
    }
  );
};
