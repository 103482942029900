import { MouseOrbitIcon, MousePanIcon, MouseResetIcon, MouseZoomIcon } from '../Common/Icons';

export const MouseDetails = () => {
  return (
    <div className="mt-8 flex flex-col">
      <div className="flex flex-row gap-[60px]">
        <div className="self-end">
          <MouseOrbitIcon />
          <div className="mt-5 text-sm text-center">Orbit</div>
        </div>
        <div className="self-end">
          <MousePanIcon />
          <div className="mt-5 text-sm text-center">Pan</div>
        </div>
        <div className="self-end">
          <MouseZoomIcon />
          <div className="mt-5 text-sm text-center">Zoom</div>
        </div>
        <div className="self-end">
          <MouseResetIcon />
          <div className="mt-5 text-sm text-center">Reset</div>
        </div>
      </div>
    </div>
  );
};
