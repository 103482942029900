import { AreaConfig, CategoryGroup, CategoryId, SubjectConfig } from '#server/models';
import { CategoryDetail } from './Category';

export const SubjectDetailsView = ({
  areaConfig,
  modelConfig,
  categoryGroups
}: {
  areaConfig: AreaConfig | undefined;
  modelConfig: SubjectConfig | undefined;
  categoryGroups: CategoryGroup[] | null;
}) => {
  const modelType = modelConfig?.type;
  const discipline = areaConfig?.disciplines.find(
    (discipline) => discipline.id === modelConfig?.discipline
  );
  const subjectCategoryIds = modelConfig?.categoryIds;

  // Create a map of category IDs to names
  const categoryMap = new Map<number, string>();
  categoryGroups?.forEach((group) => {
    group.categories.forEach((category) => {
      categoryMap.set(category.id, category.name);
    });
  });

  // Find matching category names in the map and filter out null values
  const subjectCategories = subjectCategoryIds
    ?.map((category) => ({
      ...category,
      name: categoryMap.get(category.id) || null
    }))
    .filter((category) => category.name !== null);

  return modelConfig ? (
    <div className="flex flex-col gap-y-8 p-4">
      <div>
        <p className="text-xl font-bold">{modelConfig?.title}</p>
        <p className="text-base font-normal pt-8 w-5/6">{modelConfig?.description}</p>
      </div>
      <div>
        <div className="text-base font-bold">Categories</div>
      </div>
      <div className="flex flex-wrap gap-3 text-xs font-normal">
        {subjectCategories?.map((obj) => (
          <CategoryDetail categoryId={obj.id} categoryName={obj.name} />
        ))}
        {discipline ? (
          <CategoryDetail categoryId={modelConfig?.discipline} categoryName={discipline.name} />
        ) : (
          ''
        )}
        {modelType ? (
          <CategoryDetail categoryId={modelConfig?.type} categoryName={modelType} />
        ) : (
          ''
        )}
      </div>
    </div>
  ) : (
    ''
  );
};
