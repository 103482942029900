import { forwardRef } from 'react';
import { SideMenuViews } from '../SideMenu';
import { ToolboxViews } from '../Toolbox';
import { SideMenuState } from '../Layout';
import { AreaConfig } from '#server/models';
import { Menu } from 'lucide-react';
import { CurtinLogo } from './CurtinLogo';
import { NavBarMenu } from './NavBarMenu';
import { ToolboxPanel } from './ToolboxPanel';
import { useDeviceType } from '@/hooks';

export interface HeaderProps {
  title: string;
  openAreaSlugModal: (e: any) => void;
  sideMenuState: SideMenuState;
  setToolboxView: (view: ToolboxViews) => void;
  toolboxState: {
    open: boolean;
    view: ToolboxViews;
  };
  toggleResetView: () => void;
  navBarMobileOpen: boolean;
  setnavBarMobileOpen: (on: boolean) => void;
  openSideMenuView: (view: SideMenuViews) => (e: any) => void;
  areaConfig: AreaConfig | undefined;
  modelConfigAvailable: boolean;
}

export const Header = forwardRef<HTMLElement, HeaderProps>(
  (
    {
      title,
      openAreaSlugModal,
      sideMenuState,
      setToolboxView,
      toolboxState,
      toggleResetView,
      navBarMobileOpen,
      setnavBarMobileOpen,
      openSideMenuView,
      areaConfig,
      modelConfigAvailable
    },
    ref
  ) => {
    const { isMobile } = useDeviceType();

    return (
      <nav
        ref={ref}
        className={`bg-black flex flex-row md:h-[60px] h-[56px] justify-between py-3 ${
          isMobile ? 'px-2' : 'px-8 border-b-[1px]'
        } ${
          navBarMobileOpen ? 'border-[#C3D82E] border-b-[1px]' : 'border-[#b3b3b3] border-b-[0px]'
        }`}
      >
        <div className="basis-3/4 flex gap-16 h-full items-center">
          <div className="flex gap-5">
            <CurtinLogo />
            <div className="font-bold text-xs flex flex-col text-[#F0F0F0]">
              {title !== '' ? (
                <a
                  href={`/view/${title.toLowerCase()}`}
                  onClick={openAreaSlugModal}
                  className="flex flex-col"
                >
                  <span>CURTIN</span>
                  <span className="font-normal">3D Viewer</span>
                </a>
              ) : (
                <div className="flex flex-col">
                  <span>CURTIN</span>
                  <span className="font-normal">3D Viewer</span>
                </div>
              )}
            </div>
          </div>
          {!isMobile && areaConfig && (
            <NavBarMenu
              sideMenuState={sideMenuState}
              openSideMenuView={openSideMenuView}
              areaConfig={areaConfig}
              modelConfigAvailable={modelConfigAvailable}
            />
          )}
        </div>
        {modelConfigAvailable && !isMobile && (
          <ToolboxPanel
            className="basis-1/4 justify-end flex gap-4 items-center"
            setToolboxView={setToolboxView}
            toolboxState={toolboxState}
            toggleResetView={toggleResetView}
          />
        )}

        <Menu
          className={`${!isMobile ? 'hidden' : ''} size-8 mr-1`}
          onClick={() => setnavBarMobileOpen(!navBarMobileOpen)}
        />
      </nav>
    );
  }
);
