export const KeyboardPanIcon = () => (
  <svg width="138" height="66" viewBox="0 0 138 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="48" height="28" rx="5" stroke="#E8E8E8" stroke-width="2" />
    <path
      d="M15.116 19.168C13.492 19.168 12.302 18.552 11.532 17.684L12.218 16.802C12.834 17.488 13.842 18.132 15.158 18.132C16.824 18.132 17.356 17.236 17.356 16.522C17.356 14.114 11.826 15.444 11.826 12.112C11.826 10.572 13.198 9.522 15.032 9.522C16.46 9.522 17.566 9.998 18.35 10.824L17.65 11.678C16.95 10.908 15.97 10.558 14.948 10.558C13.828 10.558 13.03 11.16 13.03 12.042C13.03 14.142 18.56 12.938 18.56 16.424C18.56 17.782 17.636 19.168 15.116 19.168ZM25.8811 19H24.8311V14.548C24.8311 13.358 24.2151 13.008 23.3051 13.008C22.4931 13.008 21.7091 13.512 21.3031 14.058V19H20.2531V9.662H21.3031V13.218C21.7791 12.658 22.7031 12.07 23.7251 12.07C25.1391 12.07 25.8811 12.756 25.8811 14.226V19ZM28.4957 11.272C28.1177 11.272 27.7957 10.964 27.7957 10.572C27.7957 10.18 28.1177 9.858 28.4957 9.858C28.8877 9.858 29.2097 10.18 29.2097 10.572C29.2097 10.964 28.8877 11.272 28.4957 11.272ZM29.0277 19H27.9777V12.238H29.0277V19ZM32.4663 19H31.4163V13.162H30.2963V12.238H31.4163V11.72C31.4163 10.334 32.1723 9.522 33.3763 9.522C33.9083 9.522 34.3843 9.648 34.8043 10.04L34.3703 10.726C34.1463 10.53 33.8803 10.39 33.5303 10.39C32.8443 10.39 32.4663 10.866 32.4663 11.72V12.238H33.8383V13.162H32.4663V19ZM36.7951 19.168C35.8011 19.168 35.2971 18.594 35.2971 17.586V13.162H34.1771V12.238H35.2971V10.39H36.3471V12.238H37.7191V13.162H36.3471V17.362C36.3471 17.866 36.5711 18.23 37.0331 18.23C37.3271 18.23 37.6071 18.104 37.7471 17.95L38.0551 18.734C37.7891 18.986 37.4111 19.168 36.7951 19.168Z"
      fill="white"
    />
    <path
      d="M64.55 14.636H61.328V18.29H59.762V14.636H56.522V13.214H59.762V9.704H61.328V13.214H64.55V14.636Z"
      fill="white"
    />
    <rect x="73" y="1" width="28" height="28" rx="5" stroke="#E8E8E8" stroke-width="2" />
    <path
      d="M93 18L87 12L81 18"
      stroke="#E8E8E8"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect x="109" y="1" width="28" height="28" rx="5" stroke="#E8E8E8" stroke-width="2" />
    <path
      d="M117 12L123 18L129 12"
      stroke="#E8E8E8"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect x="73" y="37" width="28" height="28" rx="5" stroke="#E8E8E8" stroke-width="2" />
    <path
      d="M90 45L84 51L90 57"
      stroke="#E8E8E8"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect x="109" y="37" width="28" height="28" rx="5" stroke="#E8E8E8" stroke-width="2" />
    <path
      d="M120 57L126 51L120 45"
      stroke="#E8E8E8"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
