import { NoToneMapping } from 'three';
import { Suspense, useState } from 'react';
import { useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { Canvas } from '@react-three/fiber';
import { Perf } from 'r3f-perf';
import { Modal, AccessDenied, ServerError, NotFound, ToolboxPanel } from '@/components';
import { useAreaConfig, useElementSize, useDeviceType, useSubjectConfig } from '@/hooks';
import { Experience } from './Experience';
import { Loader } from '../Common';
import { Raycast } from './Raycast';
import { Annotations } from './Annotations';

export const ThreeDeeViewer = () => {
  const [
    sideMenuState,
    setSideMenuState,
    subjectViewState,
    setSubjectViewState,
    toggleResetView,
    setToolboxView,
    toolboxState,
    searchLabelsState,
    getUpdatedLabels,
    headerHeight,
    annotationsState,
    setAnnotationsState
  ]: any = useOutletContext();
  const { subjectSlug, areaSlug } = useParams();
  const [searchParams] = useSearchParams();
  const {
    data: modelData,
    error: modelError,
    isLoading: modelIsLoading
  } = useSubjectConfig(subjectSlug, '3D', areaSlug);
  const { data: areaConfig, error: areaError, isLoading: areaIsLoading } = useAreaConfig(areaSlug);
  const [hasConditionAccepted, setHasConditionAccepted] = useState<boolean>(
    () => !!localStorage.getItem('context-and-conditions-of-use')
  );
  const { isMobile } = useDeviceType();
  const error = modelError || areaError;
  const [toolboxRef, { height: toolboxHeight }] = useElementSize();
  const [subjectProgress, setSubjectProgress] = useState(0);

  const isLoading = modelIsLoading || areaIsLoading;

  if (error) {
    switch (error.data?.code) {
      case 'FORBIDDEN':
        return <AccessDenied error={error.data.accessError as any} />;
      case 'NOT_FOUND':
        return (
          <NotFound
            message={
              'The model you were trying to view could not be found. Please check the link provided.'
            }
          />
        );
      default:
        return <ServerError />;
    }
  }

  const dialogTitle = 'Conditions of use';
  const dialogDescription = `This resource shows realistic images of human tissue which some users may find confronting. By clicking 'I agree' I indicate that I will comply with the conditions of use as stipulated in the Anatomy Compliance Test. Reproduction of the imagery in any format, digital or otherwise, is strictly forbidden.`;
  const dialogOkText = 'I AGREE';

  const okAction = (e: any) => {
    e.preventDefault();
    localStorage.setItem('context-and-conditions-of-use', 'accepted');
    setHasConditionAccepted(true);
  };

  const handleSubjectLoadProgress = (progress: number) => {
    setSubjectProgress(progress);
  };

  return (
    <>
      {isMobile && (
        <ToolboxPanel
          className={'justify-center flex gap-4 items-center mt-3 mb-2'}
          setToolboxView={setToolboxView}
          toolboxState={toolboxState}
          toggleResetView={toggleResetView}
          ref={toolboxRef}
        />
      )}
      {!hasConditionAccepted && !isLoading && !error && (
        <Modal
          title={dialogTitle}
          description={dialogDescription}
          hideXButton={true}
          okButtonTitle={dialogOkText}
          okAction={okAction}
        />
      )}
      {hasConditionAccepted && areaConfig && modelData ? (
        <div style={{ width: '100vw', height: `calc(100dvh - ${headerHeight + toolboxHeight}px)` }}>
          <Canvas
            gl={{
              precision: 'highp',
              toneMapping: NoToneMapping,
              pixelRatio: window.devicePixelRatio,
              autoClear: false
            }}
            camera={{
              position: [0, 0, modelData.config3D!.dist],
              fov: 45,
              near: 0.1,
              far: 10000
            }}
            onDoubleClick={() => toggleResetView()}
          >
            <>
              {searchParams.has('debug') && <Perf />}
              <Suspense fallback={<Loader progress={subjectProgress} />}>
                {searchParams.has('raycast') && <Raycast />}
                <Experience
                  subjectModelPath={modelData.config3D!.modelPath}
                  subjectTexturePath={modelData.config3D!.texturePath}
                  referenceModelPath={modelData.config3D!.referenceContextModelPath}
                  subjectContextModelPath={modelData.config3D!.contextModelPath}
                  modelConfig={modelData}
                  areaConfig={areaConfig}
                  setSideMenuState={setSideMenuState}
                  subjectViewState={subjectViewState}
                  setSubjectViewState={setSubjectViewState}
                  searchLabelsState={searchLabelsState}
                  getUpdatedLabels={getUpdatedLabels}
                  toggleResetView={toggleResetView}
                  handleSubjectLoadProgress={handleSubjectLoadProgress}
                />
              </Suspense>
            </>
          </Canvas>
          <Annotations
            headerHeight={headerHeight}
            state={annotationsState}
            setState={setAnnotationsState}
          />
        </div>
      ) : null}
    </>
  );
};
