import { TouchOrbitIcon, TouchPanIcon, TouchResetIcon, TouchZoomIcon } from '../Common/Icons';

export const TouchDetails = () => {
  return (
    <div className="mt-8 flex flex-col">
      <div className="flex flex-row gap-[60px]">
        <div className="self-end">
          <TouchOrbitIcon />
          <div className="mt-5 text-sm text-center">Orbit</div>
        </div>
        <div className="self-end">
          <TouchPanIcon />
          <div className="mt-5 text-sm text-center">Pan</div>
        </div>
        <div className="self-end">
          <TouchZoomIcon />
          <div className="mt-5 text-sm text-center">Zoom</div>
        </div>
        <div className="self-end">
          <TouchResetIcon />
          <div className="mt-5 text-sm text-center">Reset</div>
        </div>
      </div>
    </div>
  );
};
