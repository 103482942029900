import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useAuth } from 'react-oidc-context';
import { Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import { DXP, DXPAnalytics } from '@curtin-dxp/web-client';
import { trpc } from '@/data-loaders';
import { SignIn, Layout, AreaLanding, ViewerRouter } from '@/components';
import { RumClient } from '@/services';
import { apiConfig, authConfig, gmtConfig, rumConfig, DXPConfig } from './env-exports';
import './index.css';

let accessToken: string | undefined = undefined;

let referrer = document?.referrer ?? '';

const getReferrer = () => {
  const restoredReferrer = localStorage.getItem('signin-restore-referrer') ?? referrer;
  localStorage.removeItem('signin-restore-referrer');
  referrer = '';
  return restoredReferrer;
};

const apiUri = apiConfig.apiBaseUri ?? window.location.origin;

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Navigate to="/view/medical" />
      },
      {
        path: '/signin',
        element: <SignIn />
      },
      {
        path: '/signout',
        element: <Navigate to="/" />
      },
      {
        path: '/view/:areaSlug',
        children: [
          {
            index: true,
            element: <AreaLanding />
          },
          {
            path: ':viewerType/:subjectSlug',
            element: <ViewerRouter />
          }
        ]
      }
    ]
  }
]);

export default function App() {
  const auth = useAuth();

  if (!auth.isAuthenticated && !auth.isLoading) {
    localStorage.removeItem('context-and-conditions-of-use');
    if (window.location.pathname && window.location.pathname !== '/signin') {
      localStorage.setItem(
        'signin-restore-pathname',
        `${window.location.pathname}${window.location.search}`
      );
      localStorage.setItem('signin-restore-referrer', document.referrer);
    }

    auth.signinRedirect();
  }

  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: `${apiUri}/api/trpc`,
          // You can pass any HTTP headers you wish here
          async headers() {
            return {
              authorization: `Bearer ${accessToken}`
            };
          }
        })
      ]
    })
  );

  if (auth.isAuthenticated) {
    accessToken = auth.user?.access_token;
  }

  useEffect(() => {
    if (gmtConfig.enabled) {
      TagManager.initialize(gmtConfig.config);
    }
    if (rumConfig.appMonitorId) {
      RumClient.initialise({
        appMonitorId: rumConfig.appMonitorId,
        identityPoolId: authConfig.identityPoolId,
        guestRoleArn: authConfig.unauthRoleArn,
        sessionSampleRate: 1,
        region: authConfig.identityPoolRegion,
        apiUri
      });
    }

    DXP.configure(DXPConfig);
    DXPAnalytics.autoTrackPageViews({
      type: 'SPA',
      getUrl: () => {
        const search = window.location.search.startsWith('?code=') ? '' : window.location.search; // don't track auth code in search
        return window.location.origin + window.location.pathname + search;
      },
      getReferrer
    });
    DXPAnalytics.attributeTrack('LINK');
  }, []);

  return (
    <>
      {auth.isAuthenticated ? (
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </trpc.Provider>
      ) : null}
    </>
  );
}
