import { useRef } from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  TooltipArrow
} from '@/components/ui/tooltip';
import { ToolboxViews } from '../Toolbox';
import { ToolboxIcon } from './ToolboxIcon';
import { useDeviceType } from '@/hooks';

interface ToolboxIconButtonProps {
  IconComponent: React.ElementType;
  tooltipContent: string;
  toolboxState: {
    open: boolean;
    view: ToolboxViews;
  };
  toolboxView?: ToolboxViews;
  clickEventHandler?: () => void;
  setToolboxView: (view: ToolboxViews) => void;
}

const noop = () => {};

export const ToolboxIconButton = ({
  IconComponent,
  tooltipContent,
  toolboxState,
  toolboxView,
  clickEventHandler = noop,
  setToolboxView
}: ToolboxIconButtonProps) => {
  const toolboxIconButtonRef = useRef<HTMLButtonElement>(null);

  const handleToolboxIconButtonClick = (
    toolboxView: ToolboxViews | undefined,
    clickEventHandler: () => void
  ) => {
    if (toolboxView) {
      setToolboxView(toolboxView);
    }
    clickEventHandler();
  };
  const { isMobile } = useDeviceType();
  return (
    <>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger className="group outline-none" tabIndex={-1}>
            <button
              ref={toolboxIconButtonRef}
              className={`flex items-center justify-center outline-none h-8 w-8 rounded-full
              ${isMobile ? 'bg-black group-hover:bg-opacity-10' : 'bg-[#F0F0F0] bg-opacity-10 group-hover:bg-opacity-100'} group-hover:rounded-full 
               focus-visible:ring-2 focus-visible:ring-ring
              focus-visible:rounded-none data-[state=active]:bg-[#F0F0F0]
              data-[state=active]:focus-visible:ring-0
              data-[state=active]:focus-visible:rounded-full`}
              tabIndex={0}
              data-state={
                toolboxState?.open && toolboxState?.view === toolboxView ? 'active' : 'inactive'
              }
              aria-label={tooltipContent}
              onClick={() => handleToolboxIconButtonClick(toolboxView, clickEventHandler)}
            >
              <ToolboxIcon
                IconComponent={IconComponent}
                toolboxView={toolboxView}
                toolboxState={toolboxState}
              />
            </button>
          </TooltipTrigger>
          <TooltipContent className="bg-[#F0F0F0] text-black border-none" sideOffset={16}>
            {tooltipContent}
            <TooltipArrow className="animate-in fill-[#F0F0F0]" />
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </>
  );
};
