import { SideMenuState } from '../Layout';
import { SideMenuViews } from '../SideMenu';
import { useDeviceType } from '@/hooks';

interface NavBarMenuItemProps {
  label: string;
  sideMenuView: SideMenuViews;
  sideMenuState: SideMenuState;
  openSideMenuView: (view: SideMenuViews) => (e: any) => void;
}

export const NavBarMenuItem = ({
  label,
  sideMenuView,
  sideMenuState,
  openSideMenuView
}: NavBarMenuItemProps) => {
  const { isMobile } = useDeviceType();
  const { open, view } = sideMenuState;
  return (
    <li
      className={`${isMobile ? 'border-b-[1px] border-[#4B4B4B] pl-4 py-4 h-[60px] w-full' : ''}
      relative flex items-center h-full`}
    >
      <a
        href="#"
        className={`${open && view === sideMenuView ? 'text-lime' : 'text-white'} hover:text-lime
        group`}
        onClick={openSideMenuView(sideMenuView)}
      >
        <div className="first-letter:uppercase text-[#F0F0F0]">{label}</div>
        <div
          className={`${!isMobile && open && view === sideMenuView ? 'opacity-100' : 'opacity-0'}
          absolute left-0 ${isMobile ? '-bottom-[0.3rem]' : '-bottom-[12px]'} w-full h-1 bg-lime
          group-hover:opacity-100 transition-opacity duration-300`}
        ></div>
      </a>
    </li>
  );
};
