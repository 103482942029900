export type CrossIconProps = {
  className?: string;
};

export const CrossIcon = ({ className }: CrossIconProps) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      id="Path"
      d="M20.21 1.71004L18.79 0.290039L10.5 8.59004L2.20999 0.290039L0.789993 1.71004L9.08999 10L0.789993 18.29L2.20999 19.71L10.5 11.41L18.79 19.71L20.21 18.29L11.91 10L20.21 1.71004Z"
      fill="#E8E8E8"
    />
  </svg>
);
