export const KeyboardZoomIcon = () => (
  <svg width="66" height="30" viewBox="0 0 66 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="28" height="28" rx="5" stroke="#E8E8E8" stroke-width="2" />
    <path
      d="M18.55 15.636H15.328V19.29H13.762V15.636H10.522V14.214H13.762V10.704H15.328V14.214H18.55V15.636Z"
      fill="white"
    />
    <rect x="37" y="1" width="28" height="28" rx="5" stroke="#E8E8E8" stroke-width="2" />
    <path d="M52.86 17.616H48.54V15.672H52.86V17.616Z" fill="white" />
  </svg>
);
