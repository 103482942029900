import * as React from 'react';
import * as SwitchPrimitives from '@radix-ui/react-switch';

import { cn } from '@/lib/utils';

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      `peer inline-flex h-[18px] w-[38px] shrink-0 cursor-pointer items-center rounded-full border-2
      border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2
      focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background
      disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary
      data-[state=unchecked]:bg-[#F0F0F0]`,
      className
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        `pointer-events-none block h-4 w-4 rounded-full bg-background shadow-lg ring-0
        transition-transform data-[state=checked]:translate-x-[18px]
        data-[state=unchecked]:translate-x-0`
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
// updated h-5 w-10 to h-[18px] w-[38px] in SwitchPrimitives.Root and translate-x-5  to translate-x-[18px] in SwitchPrimitives.Thumb
