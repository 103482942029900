export const ThreeSixtyIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#C3D82E" fill-opacity="0.2" />
    <path
      d="M12.0004 17.3334C9.28761 17.3334 6.73227 16.9088 4.80566 16.1384C2.78197 15.3288 1.66742 14.2143 1.66742 13.0002C1.66742 12.0827 2.2999 11.2198 3.49611 10.504C3.65403 10.4094 3.85902 10.4611 3.95318 10.619C4.04777 10.7769 3.9961 10.9815 3.83819 11.076C3.15196 11.4864 2.33365 12.1535 2.33365 12.9997C2.33365 13.9097 3.32487 14.8276 5.05274 15.5188C6.90268 16.2588 9.37011 16.6663 12 16.6663C14.6299 16.6663 17.0974 16.2588 18.9473 15.5188C20.6752 14.8276 21.6664 13.9097 21.6664 12.9997C21.6664 12.1614 20.8531 11.4914 20.1677 11.076C20.1573 11.0702 20.1465 11.064 20.136 11.0573L20.1348 11.0565C19.9769 10.9715 19.914 10.7756 19.9948 10.614C20.0773 10.4494 20.2773 10.3827 20.4419 10.4648C20.4577 10.4727 20.4735 10.4823 20.4894 10.4919L20.4906 10.4927C20.4956 10.4952 20.5006 10.4981 20.5052 10.5011C21.7006 11.2235 22.3326 12.0877 22.3326 12.9997C22.3326 14.2139 21.2181 15.3284 19.1944 16.138C17.2674 16.9088 14.7124 17.3329 11.9996 17.3329L12.0004 17.3334ZM20.1427 11.0606C20.1427 11.0606 20.144 11.0615 20.1448 11.0615C20.1444 11.0615 20.1435 11.0606 20.1427 11.0606ZM20.4823 10.4886C20.4823 10.4886 20.4823 10.4886 20.4827 10.4886C20.4827 10.4886 20.4827 10.4886 20.4823 10.4886Z"
      fill="#C3D82E"
    />
    <path
      d="M12.0003 18.999C11.9149 18.999 11.8299 18.9665 11.7645 18.9016C11.6345 18.7716 11.6345 18.5603 11.7645 18.4303L13.1953 16.9995L11.7645 15.5687C11.6345 15.4387 11.6345 15.2275 11.7645 15.0975C11.8945 14.9675 12.1057 14.9675 12.2357 15.0975L13.9023 16.7641C14.0323 16.8941 14.0323 17.1054 13.9023 17.2354L12.2357 18.902C12.1707 18.967 12.0853 18.9995 11.9999 18.9995L12.0003 18.999Z"
      fill="#C3D82E"
    />
    <path
      d="M11.8337 13.3335C10.8229 13.3335 10.0004 12.5315 10.0004 11.5457C10.0004 10.5599 10.8217 9.75781 11.8308 9.75781C12.8399 9.75781 13.667 10.5599 13.667 11.5457C13.667 12.5315 12.8445 13.3335 11.8337 13.3335ZM11.8337 10.4245C11.1891 10.4245 10.6671 10.9261 10.6671 11.5428C10.6671 12.1594 11.1904 12.6669 11.8337 12.6669C12.477 12.6669 13.0003 12.164 13.0003 11.5457C13.0003 10.9274 12.477 10.4245 11.8337 10.4245Z"
      fill="#C3D82E"
    />
    <path
      d="M16.1671 13.3338C15.1563 13.3338 14.3339 12.5317 14.3339 11.5459V9.12185C14.3339 8.13604 15.1563 7.33398 16.1671 7.33398C17.1779 7.33398 18.0004 8.13604 18.0004 9.12185V11.5459C18.0004 12.5317 17.1779 13.3338 16.1671 13.3338ZM16.1671 8.00063C15.5238 8.00063 15.0005 8.50353 15.0005 9.12185V11.5459C15.0005 12.1643 15.5238 12.6672 16.1671 12.6672C16.8105 12.6672 17.3338 12.1643 17.3338 11.5459V9.12185C17.3338 8.50353 16.8105 8.00063 16.1671 8.00063Z"
      fill="#C3D82E"
    />
    <path
      d="M7.5036 13.3328H7.49735C6.48822 13.3328 5.66699 12.5866 5.66699 11.6691C5.66699 11.485 5.81615 11.3358 6.00032 11.3358C6.18448 11.3358 6.33364 11.485 6.33364 11.6691C6.33364 12.2191 6.85571 12.6662 7.49735 12.6662H7.5036C8.14525 12.6662 8.66732 12.2187 8.66732 11.6691V11.6637C8.66732 11.1137 8.14525 10.6666 7.5036 10.6666C7.31944 10.6666 7.17028 10.5175 7.17028 10.3333C7.17028 10.1492 7.31944 10 7.5036 10C8.51274 10 9.33397 10.7462 9.33397 11.6637V11.6691C9.33397 12.5866 8.51274 13.3328 7.5036 13.3328Z"
      fill="#C3D82E"
    />
    <path
      d="M7.5036 10.6668C7.31944 10.6668 7.17028 10.5176 7.17028 10.3335C7.17028 10.1493 7.31944 10.0002 7.5036 10.0002C8.14525 10.0002 8.66732 9.55267 8.66732 9.0031V8.99769C8.66732 8.4477 8.14525 8.00063 7.5036 8.00063H7.49735C6.85571 8.00063 6.33364 8.44812 6.33364 8.99769C6.33364 9.18185 6.18448 9.33101 6.00032 9.33101C5.81615 9.33101 5.66699 9.18185 5.66699 8.99769C5.66699 8.08021 6.48822 7.33398 7.49735 7.33398H7.5036C8.51274 7.33398 9.33397 8.08021 9.33397 8.99769V9.0031C9.33397 9.92057 8.51274 10.6668 7.5036 10.6668Z"
      fill="#C3D82E"
    />
    <path
      d="M10.3338 11.8793C10.1496 11.8793 10.0004 11.7301 10.0004 11.546C10.0004 9.61188 10.7437 8.57274 11.3675 8.04109C12.0566 7.45402 12.7541 7.34194 12.7833 7.33778C12.9653 7.31028 13.1353 7.43527 13.1628 7.61694C13.1907 7.79901 13.0653 7.96901 12.8837 7.99651C12.7949 8.01109 10.6667 8.39525 10.6667 11.5456C10.6667 11.7297 10.5175 11.8789 10.3333 11.8789L10.3338 11.8793Z"
      fill="#C3D82E"
    />
    <path
      d="M19.0002 7.66659C18.2652 7.66659 17.6669 7.06869 17.6669 6.33329C17.6669 5.5979 18.2652 5 19.0002 5C19.7352 5 20.3335 5.5979 20.3335 6.33329C20.3335 7.06869 19.7352 7.66659 19.0002 7.66659ZM19.0002 5.66665C18.6327 5.66665 18.3336 5.9658 18.3336 6.33329C18.3336 6.70078 18.6327 6.99994 19.0002 6.99994C19.3677 6.99994 19.6669 6.70078 19.6669 6.33329C19.6669 5.9658 19.3677 5.66665 19.0002 5.66665Z"
      fill="#C3D82E"
    />
  </svg>
);
