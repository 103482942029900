import { KeyboardOrbitIcon, KeyboardPanIcon, KeyboardResetIcon, KeyboardZoomIcon } from '../Common';

export const KeyboardDetails = () => {
  return (
    <div className="mt-8 flex flex-col pb-8 lg:pb-0">
      <div className="flex flex-row gap-10 flex-wrap justify-evenly">
        <div className="self-end">
          <KeyboardOrbitIcon />
          <div className="mt-5 text-sm text-center">Orbit</div>
        </div>
        <div className="self-end">
          <KeyboardPanIcon />
          <div className="mt-5 text-sm text-center">Pan</div>
        </div>
        <div className="self-end">
          <KeyboardZoomIcon />
          <div className="mt-5 text-sm text-center">Zoom</div>
        </div>
        <div className="self-end">
          <KeyboardResetIcon />
          <div className="mt-5 text-sm text-center">Reset</div>
        </div>
      </div>
    </div>
  );
};
