import { Html } from '@react-three/drei';
import { Spinner } from '../Icons';

export const Loader = ({ progress = 0 }) => {
  const getLoadingText = (progress: number) => {
    if (progress === 0) {
      return 'Loading...';
    } else if (progress < 100) {
      return 'Progress: ' + Math.ceil(progress) + '%';
    } else {
      return 'Preparing...';
    }
  };

  return (
    <Html center>
      <div className="text-center">
        <output aria-live="polite" aria-busy="true">
          <Spinner />
        </output>
        <div className="pt-2 whitespace-nowrap">
          <span>{getLoadingText(progress)}</span>
        </div>
      </div>
    </Html>
  );
};
