import { MessageCircleWarning } from 'lucide-react';
import { Button } from '@/components/ui';
import { useDeviceType } from '@/hooks';

export type BaseErrorProps = {
  title: string;
  paragraphs: string[];
  link?: {
    title: string;
    url: string;
  };
};

export const BaseError = ({ title, paragraphs, link }: BaseErrorProps) => {
  const { isMobile } = useDeviceType();
  return (
    <div className="flex justify-center items-center h-screen">
      <div className={`flex flex-col items-start ${isMobile ? 'w-2/3 h-3/5' : 'w-1/3 h-3/5'}`}>
        <MessageCircleWarning className={'mb-10 size-16'} />
        <h2 className="pb-6 font-bold">{title}</h2>
        <div className="">
          {paragraphs.map((paragraph, index) => (
            <p className={`pb-6 ${isMobile ? 'text-base' : 'text-lg'} text-white`} key={index}>
              {paragraph}
            </p>
          ))}
        </div>
        {link ? (
          <Button
            variant="secondary"
            className={`${isMobile ? 'text-sm' : 'text-base px-7'} rounded-s-md font-semibold
            leading-7 focus:rounded-none focus-visible:ring-0 focus-visible:ring-offset-0`}
            onClick={() => window.open(link.url, '_blank')}
          >
            {link.title}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
