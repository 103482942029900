import { getCommonAnalyticsContext } from '@/lib/utils';
import { DXPAnalytics } from '@curtin-dxp/web-client';
import {
  line as d3Line,
  curveBasis as d3CurveBasis,
  select as d3Select,
  drag as d3Drag,
  DragContainerElement,
  Selection
} from 'd3';

let topOffset = 0;
let annotationsBrushWidth = 5;
let annotationsColour = '#B71C1C';
let annotationsFontSize = 2;
let initialized = false;
let selectedText: any = undefined;

const appendMoveSvg = (target: Selection<HTMLDivElement, unknown, HTMLElement, any>) => {
  const moveSvg = target
    .append('svg')
    .attr('viewBox', '0 0 24 24')
    .attr('width', '24')
    .attr('height', '24')
    .attr('fill', 'none')
    .attr('stroke', 'currentColor')
    .attr('stroke-width', '2')
    .attr('stroke-linecap', 'round')
    .attr('stroke-linejoin', 'round');
  moveSvg.append('polyline').attr('points', '5 9 2 12 5 15');
  moveSvg.append('polyline').attr('points', '9 5 12 2 15 5');
  moveSvg.append('polyline').attr('points', '15 19 12 22 9 19');
  moveSvg.append('polyline').attr('points', '19 9 22 12 19 15');
  moveSvg.append('line').attr('x1', '2').attr('x2', '22').attr('y1', '12').attr('y2', '12');
  moveSvg.append('line').attr('x1', '12').attr('x2', '12').attr('y1', '2').attr('y2', '22');
};

const updateAnnotationSettings = (
  headerheight: number,
  mode: 'Brush' | 'Text',
  brushWidth: number,
  colour: string,
  fontSize: number
) => {
  topOffset = headerheight;
  annotationsBrushWidth = brushWidth;
  annotationsColour = colour;
  annotationsFontSize = fontSize;

  if (selectedText) {
    if (mode === 'Brush') {
      selectedText.classList.remove('active');
      selectedText = undefined;
    } else {
      selectedText.style.fontSize = annotationsFontSize + 'rem';
      selectedText.style.color = annotationsColour;
    }
  }
};

export const clearAnnotations = () => {
  d3Select('#annotations').selectAll('*').remove();
  d3Select('#annotations-text').selectAll('*').remove();
};

export const teardownAnnotations = () => {
  clearAnnotations();
  initialized = false;
  selectedText = undefined;
};

export const setupAnnotations = (
  headerheight: number,
  mode: 'Brush' | 'Text',
  brushWidth: number,
  colour: string,
  fontSize: number
) => {
  updateAnnotationSettings(headerheight, mode, brushWidth, colour, fontSize);

  if (initialized) return;

  let pointer: any = undefined;

  const line = d3Line().curve(d3CurveBasis);

  const dragStart = (event: any) => {
    const dragSubject = event.subject;
    const active = annotations
      .append('path')
      .attr('style', `stroke: ${annotationsColour}; stroke-width: ${annotationsBrushWidth};`)
      .datum(dragSubject);
    let x0 = event.x;
    let y0 = event.y;

    event.on('drag', function (ev: { x: any; y: any }) {
      const x1 = ev.x;
      const y1 = ev.y;
      const dx = x1 - x0;
      const dy = y1 - y0;

      if (dx * dx + dy * dy > 50) {
        x0 = x1;
        y0 = y1;
        dragSubject.push([x0, y0]);
      } else {
        dragSubject[dragSubject.length - 1] = [x1, y1];
      }
      active.attr('d', line);
      if (pointer !== null) {
        pointer.attr('cx', x1).attr('cy', y1);
      }
    });
    DXPAnalytics.trackAction({
      name: 'USE_ANNOTATION_BRUSH',
      context: getCommonAnalyticsContext()
    });
  };

  const dragBehavior = d3Drag()
    .container(function () {
      return this as DragContainerElement;
    })
    .subject(function (event) {
      const point = [event.x, event.y];
      return [point, point];
    })
    .on('start', dragStart);

  const annotations = d3Select<Element, unknown>('#annotations')
    .call(dragBehavior)
    .on('mouseenter', (event) => {
      pointer = annotations
        .append('circle')
        .attr('id', 'pointer')
        .attr('cx', event.x)
        .attr('cy', event.y - topOffset)
        .attr('r', annotationsBrushWidth / 2 + 1);
    })
    .on('mousemove', (event) => {
      pointer.attr('cx', event.x).attr('cy', event.y - topOffset);
    })
    .on('mouseleave', (event) => {
      if (pointer !== null) pointer.remove();
    });

  const textLayer = d3Select('#annotations-text');

  const handleTextClick = (event: any) => {
    const textContainer = textLayer
      .append('div')
      .attr('class', 'text-cont')
      .attr('style', `left: ${event.x}px; top: ${event.y - topOffset}px;`);
    textContainer
      .append('div')
      .attr('class', 'text-box')
      .attr('contenteditable', true)
      .attr('spellcheck', false)
      .on('touchstart', (ev) => {
        ev.preventDefault();
      })
      .on('click', (ev) => {
        ev.stopPropagation();
        if (selectedText) {
          selectedText.classList.remove('active');
        }
        selectedText = ev.target;
        selectedText.classList.add('active');
        textBox?.focus();
      })
      .on('blur', (ev) => {
        if (ev.target.innerText.trim() === '') {
          ev.target.parentElement.remove();
        } else {
          const textMove = textContainer
            .append('div')
            .attr('class', 'text-move')
            .attr('draggable', true)
            .on('dragstart', (ev) => {
              // Hide drag clone
              var img = new Image();
              ev.dataTransfer.setDragImage(img, 0, 0);
              ev.dataTransfer.setData('text', ev.target.id);
              ev.dataTransfer.effectAllowed = 'move';
            })
            .on('drag', (ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              textContainer.attr(
                'style',
                `transform: translate(${ev.x}px, ${ev.y - topOffset}px);`
              );
            })
            .on('dragover', (ev) => {
              ev.preventDefault();
            })
            .on('dragend', (ev) => {
              ev.preventDefault();
              textContainer.attr(
                'style',
                `transform: translate(${ev.x}px, ${ev.y - topOffset}px);`
              );
              ev.dataTransfer.dropEffect = 'move';
            });
          appendMoveSvg(textMove);
        }
      });

    const textBox = document.querySelector(
      '#annotations-text>.text-cont:last-child>.text-box'
    ) as HTMLElement;
    textBox.style.fontSize = annotationsFontSize + 'rem';
    textBox.style.color = annotationsColour;
    if (selectedText) {
      selectedText.classList.remove('active');
    }
    selectedText = textBox;
    selectedText.classList.add('active');
    setTimeout(() => {
      textBox?.focus();
    });

    DXPAnalytics.trackAction({
      name: 'CREATE_ANNOTATION_TEXT',
      context: getCommonAnalyticsContext()
    });
  };

  textLayer.on('click', handleTextClick);

  initialized = true;
};
