export const MousePanIcon = () => (
  <svg width="29" height="41" viewBox="0 0 29 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="Union"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.9286 3H6C4.34315 3 3 4.34315 3 6V26.5C3 32.8513 8.14873 38 14.5 38C20.8513 38 26 32.8513 26 26.5V16.9286H16.9286C15.2717 16.9286 13.9286 15.5854 13.9286 13.9286V3ZM0 6C0 2.68629 2.68629 0 6 0H23C26.3137 0 29 2.68629 29 6V26.5C29 34.5081 22.5081 41 14.5 41C6.49187 41 0 34.5081 0 26.5V6Z"
      fill="#E8E8E8"
    />
  </svg>
);
