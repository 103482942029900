import { AreaConfig } from '#server/models';
import { useDeviceType } from '@/hooks';
import { SideMenuState } from '../Layout';
import { SideMenuViews } from '../SideMenu';
import { NavBarMenuItem } from './NavBarMenuItem';
export interface NavBarMenuItemProps {
  areaConfig: AreaConfig | undefined;
  modelConfigAvailable: boolean;
  sideMenuState: SideMenuState;
  openSideMenuView: (view: SideMenuViews) => (e: any) => void;
}
export const NavBarMenu = ({
  areaConfig,  
  modelConfigAvailable,
  sideMenuState,
  openSideMenuView,
}: NavBarMenuItemProps) => {
  const { isMobile } = useDeviceType();

  return (
    <ul className={`${isMobile ? 'flex flex-col text-base font-bold h-full items-start bg-[#1A1A1A] pb-14 border-b-[1px] border-[#E8E8E8]' :'flex gap-5 text-sm font-bold h-full items-center'  }`}>
      {areaConfig ? (
        <NavBarMenuItem
          label={`Search ${areaConfig?.subjectName?.plural ?? ''}`}
          sideMenuView="Search"
          sideMenuState={sideMenuState}
          openSideMenuView={openSideMenuView}
        />
      ) : null}
      {areaConfig && modelConfigAvailable ? (
        <>
          <NavBarMenuItem
            label="Search labels"
            sideMenuView="Labels"
            sideMenuState={sideMenuState}
            openSideMenuView={openSideMenuView}
          />
          <NavBarMenuItem
            label={`${areaConfig?.subjectName?.singular ?? ''} details`}
            sideMenuView="Details"
            sideMenuState={sideMenuState}
            openSideMenuView={openSideMenuView}
          />
        </>
      ) : null}
    </ul>
  )
};
