import { useEffect } from 'react';
import { AnnotationsState } from '../Layout';
import { Slider, Tabs, TabsContent, TabsList } from '../ui';
import { StyledTabsTrigger } from '../Common/Tab';
import { DXPAnalytics } from '@curtin-dxp/web-client';
import { getCommonAnalyticsContext } from '@/lib/utils';

const colours = [
  '#B71C1C',
  '#64B5F6',
  '#0D47A1',
  '#E57373',
  '#FDD835',
  '#38B11A',
  '#FF6910',
  '#953AF9',
  '#DC1C8F',
  '#A5A5A5',
  '#009688',
  '#666666'
];

export interface AnnotationsControlsProps {
  annotationsState: AnnotationsState;
  setAnnotationsState: React.Dispatch<React.SetStateAction<AnnotationsState>>;
}

export const AnnotationControlsView = ({
  annotationsState: { mode, brushWidth, colour, fontSize },
  setAnnotationsState
}: AnnotationsControlsProps) => {
  useEffect(() => {
    setAnnotationsState((state) => ({
      ...state,
      active: true
    }));

    return () =>
      setAnnotationsState((state) => ({
        ...state,
        active: false
      }));
  }, []);

  const resetAnnotations = () => {
    setAnnotationsState((state) => ({
      ...state,
      reset: true
    }));
    DXPAnalytics.trackAction({
      name: 'RESET_ANNOTATIONS',
      context: getCommonAnalyticsContext()
    });
  };

  const onTabChange = (tab: string) =>
    setAnnotationsState((state) => ({
      ...state,
      mode: tab as 'Brush' | 'Text'
    }));

  const selectColour = (colour: string) => {
    setAnnotationsState((state) => ({
      ...state,
      colour
    }));

    DXPAnalytics.trackAction({
      name: 'SET_ANNOTATION_COLOUR',
      context: { ...getCommonAnalyticsContext(), context5: colour }
    });
  };

  return (
    <div className="mx-6 mt-8">
      <div className="flex flex-col gap-4">
        <h4 className="text-xl font-bold">Annotations</h4>
        <Tabs value={mode} onValueChange={onTabChange}>
          <TabsList className="w-full justify-between text-foreground bg-background p-4 mt-4">
            <StyledTabsTrigger value="Brush">Brush</StyledTabsTrigger>
            <StyledTabsTrigger value="Text">Text</StyledTabsTrigger>
          </TabsList>
          <TabsContent value="Brush">
            <div className="flex flex-col gap-8">
              <div className="px-4 pt-12 pb-4 flex justify-between items-center space-x-3">
                <div className="flex-none">Brush size</div>
                <div className="flex-auto text-right mr-3"> {brushWidth}</div>
                <Slider
                  className="flex-auto text-right w-1/2"
                  defaultValue={[brushWidth]}
                  min={2}
                  max={20}
                  step={1}
                  onValueChange={(value) =>
                    setAnnotationsState((state) => ({
                      ...state,
                      brushWidth: value[0]
                    }))
                  }
                  onValueCommit={(value) => {
                    DXPAnalytics.trackAction({
                      name: 'CHANGE_BRUSH_WIDTH',
                      context: { ...getCommonAnalyticsContext(), context5: value[0].toString() }
                    });
                  }}
                />
              </div>
            </div>
          </TabsContent>
          <TabsContent value="Text">
            <div className="flex flex-col gap-8">
              <div className="px-4 pt-12 pb-4 flex justify-between items-center space-x-3">
                <div className="flex-none">Font size</div>
                <div className="flex-auto text-right mr-3"> {fontSize}</div>
                <Slider
                  className="flex-auto text-right w-1/2"
                  defaultValue={[fontSize]}
                  min={1}
                  max={5}
                  step={0.5}
                  onValueChange={(value) => {
                    setAnnotationsState((state) => ({
                      ...state,
                      fontSize: value[0]
                    }));
                    DXPAnalytics.trackAction({
                      name: 'CHANGE_FONT_SIZE',
                      context: { ...getCommonAnalyticsContext(), context5: value[0].toString() }
                    });
                  }}
                />
              </div>
            </div>
          </TabsContent>
        </Tabs>
        <div className="flex flex-col gap-4 p-4 mt-4">
          <div className="text-base font-bold">Colours</div>
          <ul className="mt-4 flex flex-row gap-4 flex-wrap justify-evenly">
            {colours?.map((colourOption) => {
              const active = colourOption === colour;
              return (
                <li key={colourOption}>
                  <button
                    onClick={() => selectColour(colourOption)}
                    title={colourOption}
                    className="flex gap-4 items-center w-full"
                  >
                    <div
                      className="w-8 h-8 rounded-full hover:border-2 hover:border-[#E8E8E8]
                        data-[state=active]:border-2 data-[state=active]:border-[#E8E8E8]"
                      data-state={active ? 'active' : 'inactive'}
                      style={{
                        backgroundColor: colourOption
                      }}
                    ></div>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="p-4">
          <a
            href="#"
            className="text-[#C3D82E] underline mt-auto"
            onClick={(e) => {
              e.preventDefault();
              resetAnnotations();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                resetAnnotations();
              }
            }}
          >
            Clear all annotations
          </a>
        </div>
      </div>
    </div>
  );
};
