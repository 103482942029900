import { useElementSize } from '@/hooks';
import { Html } from '@react-three/drei';
import { useThree, useFrame } from '@react-three/fiber';
export const Raycast = () => {
  const { size } = useThree();
  const [htmlRef, { width: htmlWidth }] = useElementSize();
  useFrame(() => {
    if (htmlRef?.current?.parentElement) {
      htmlRef.current.parentElement.style.transform = `translate3d(${size.width / 2}px, 10px, 0px)`;
    }
  });
  return (
    <Html ref={htmlRef} style={{ left: -(htmlWidth / 2) }}>
      <h4
        className="whitespace-nowrap rounded-2xl border-white border-solid border-[0.5px] py-1 px-4
          uppercase tracking-[0.3px] font-normal text-xl"
      >
        Raycasting mode
      </h4>
    </Html>
  );
};
