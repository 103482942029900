export const MouseResetIcon = () => (
  <svg width="40" height="52" viewBox="0 0 40 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.8186 11C22.8781 5.87976 18.3921 2 13 2C6.92487 2 2 6.92487 2 13C2 18.3921 5.87976 22.8781 11 23.8186V21.1163C7.35029 20.22 4.64286 16.9262 4.64286 13C4.64286 8.38448 8.38448 4.64286 13 4.64286C16.9262 4.64286 20.22 7.35029 21.1163 11H23.8186ZM25.8471 11C24.8851 4.76982 19.4996 0 13 0C5.8203 0 0 5.8203 0 13C0 19.4996 4.76982 24.8851 11 25.8471V37.5C11 45.5081 17.4919 52 25.5 52C33.5081 52 40 45.5081 40 37.5V17C40 13.6863 37.3137 11 34 11H25.8471ZM19.0362 11H17C13.6863 11 11 13.6863 11 17V19.0362C8.46891 18.198 6.64286 15.8122 6.64286 13C6.64286 9.48905 9.48905 6.64286 13 6.64286C15.8122 6.64286 18.198 8.46891 19.0362 11ZM34 14H26.9286V24.9286C26.9286 26.5854 25.5854 27.9286 23.9286 27.9286H14V37.5C14 43.8513 19.1487 49 25.5 49C31.8513 49 37 43.8513 37 37.5V17C37 15.3431 35.6569 14 34 14Z"
      fill="#E8E8E8"
    />
  </svg>
);
