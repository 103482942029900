import { AnnotationsState } from '@/components/Layout';
import { useEffect } from 'react';
import { clearAnnotations, setupAnnotations, teardownAnnotations } from './service';

export const InitAnnotations = ({
  headerHeight,
  mode,
  brushWidth,
  colour,
  fontSize,
  reset,
  setState
}: {
  headerHeight: number;
  mode: AnnotationsState['mode'];
  brushWidth: AnnotationsState['brushWidth'];
  colour: AnnotationsState['colour'];
  fontSize: AnnotationsState['fontSize'];
  reset: AnnotationsState['reset'];
  setState: React.Dispatch<React.SetStateAction<AnnotationsState>>;
}) => {
  useEffect(() => {
    setupAnnotations(headerHeight, mode, brushWidth, colour, fontSize);
  }, [headerHeight, mode, brushWidth, colour, fontSize]);

  useEffect(() => {
    if (reset) {
      clearAnnotations();
      setState((state) => ({ ...state, reset: false }));
    }
  }, [reset]);

  // clear on unmount
  useEffect(() => {
    return () => teardownAnnotations();
  }, []);

  return null;
};

export interface AnnotationsProps {
  headerHeight: number;
  state: AnnotationsState;
  setState: React.Dispatch<React.SetStateAction<AnnotationsState>>;
}

export const Annotations = ({
  headerHeight,
  state: { active, mode, brushWidth, colour, fontSize, reset },
  setState
}: AnnotationsProps) => {
  return active ? (
    <div>
      <svg
        id="annotations"
        data-state={mode === 'Brush' ? 'active' : 'inactive'}
        className="absolute left-0 w-full h-full overflow-hidden pointer-events-none
          data-[state=active]:pointer-events-auto z-[17777215]"
        style={{ top: `${headerHeight}px` }}
      ></svg>
      <div
        id="annotations-text"
        data-state={mode === 'Text' ? 'active' : 'inactive'}
        className="absolute left-0 w-full h-full overflow-hidden pointer-events-none
          data-[state=active]:pointer-events-auto z-[17777215]"
        style={{ top: `${headerHeight}px` }}
      ></div>
      <InitAnnotations
        headerHeight={headerHeight}
        mode={mode}
        brushWidth={brushWidth}
        colour={colour}
        fontSize={fontSize}
        reset={reset}
        setState={setState}
      />
    </div>
  ) : null;
};
