import { Object3D, Object3DEventMap } from 'three';
import { RefObject, useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { Html } from '@react-three/drei';
import { rgba } from 'polished';
import { LabelConfig } from '#server/models';

export interface LabelModel extends LabelConfig {
  isLocked: boolean;
  index: number;
  color: string;
}

export interface LabelProps {
  label: LabelModel;
  subjectRef: RefObject<Object3D<Object3DEventMap>>;
  selectLabel: (label: LabelModel) => void;
  labelsSize: number;
  labelsOnHover: boolean;
  labelsWithNumbers: boolean;
}

export const Label = function ({
  label,
  subjectRef,
  selectLabel,
  labelsSize,
  labelsOnHover,
  labelsWithNumbers
}: Readonly<LabelProps>) {
  const [isHovered, setIsHovered] = useState(false);
  const htmlRef = useRef<HTMLDivElement>(null);

  const setIsHovereIsTrue = () => {
    setIsHovered(true);
  };

  const setIsHoverToFalse = () => {
    setIsHovered(false);
  };

  const handleOnClick = () => {
    selectLabel(label);
  };

  const displayLabel = (label: LabelModel) => {
    if (label.isLocked) {
      return labelsWithNumbers ? label.index + 1 + '. ' + label.title : label.title;
    } else {
      return labelsWithNumbers ? label.index + 1 : label.title;
    }
  };

  useFrame(() => {
    // makes sure the label is always on top of the pins by setting the z-index to its max value
    if (isHovered || label.isLocked) {
      htmlRef.current?.parentElement?.style.setProperty('z-index', '16777215');
    }
  });

  return (
    <Html
      ref={htmlRef}
      position={[
        label.config3D?.position[0]!,
        label.config3D?.position[1]!,
        label.config3D?.position[2]!
      ]}
      occlude={[subjectRef]}
    >
      <div
        onMouseOver={setIsHovereIsTrue}
        onMouseOut={setIsHoverToFalse}
        onFocus={setIsHovereIsTrue}
        onBlur={setIsHoverToFalse}
      >
        <button
          className="h-2.5 w-2.5 border-2 border-white cursor-pointer pointer-events-auto transform
            -translate-x-1/2 -translate-y-1/2 rounded-full transition-colors duration-200 ease-in"
          style={{ backgroundColor: label.color }}
          aria-label={label.title}
          onClick={handleOnClick}
        ></button>
        <button
          className={`${!labelsOnHover || isHovered || label.isLocked ? 'block' : 'hidden'}
          text-white -mt-3 ml-1 bg-black border-[3px] whitespace-nowrap cursor-pointer bg-opacity-65`}
          style={{
            borderColor: label.isLocked ? label.color : rgba(0, 0, 0, 0.65),
            fontSize: `${labelsSize}rem`,
            paddingLeft: `${labelsSize + 0.35}rem`,
            paddingRight: `${labelsSize + 0.35}rem`,
            paddingTop: `${labelsSize - 0.5}rem`,
            paddingBottom: `${labelsSize - 0.5}rem`,
            borderRadius: `${labelsSize + 0.1}rem`
          }}
          onClick={handleOnClick}
        >
          {displayLabel(label)}
        </button>
      </div>
    </Html>
  );
};
