import { ChangeEventHandler, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { SideMenu, Header, SideMenuViews, Toolbox, ToolboxViews, Modal } from '@/components';
import { useAreaConfig, useDeviceType, useElementSize, useSubjectConfig } from '@/hooks';
import { SubjectViewState } from '../ThreeDeeViewer/Experience/Subject';
import { useParams, useSearchParams } from 'react-router-dom';
import { Category, LabelConfig, Unit, ViewerType } from '#server/models';
import { NavBarMenuMobile } from '../Header/NavBarMenuMobile';
import { DXPAnalytics } from '@curtin-dxp/web-client';
import { getCommonAnalyticsContext } from '@/lib/utils';

export interface SideMenuState {
  open: boolean;
  view: SideMenuViews;
}

export interface ToolboxState {
  open: boolean;
  view: ToolboxViews;
}

export interface SearchLabelState {
  labelSearchFilter: string | undefined;
  structuresFilter: Category[];
  unitsFilter: Unit[];
}

export interface AnnotationsState {
  active: boolean;
  mode: 'Brush' | 'Text';
  brushWidth: number;
  colour: string;
  fontSize: number;
  reset: boolean;
}

export const Layout = () => {
  const { isMobile } = useDeviceType();

  const [areaNavigationWarningState, setAreaNavigationWarningState] = useState<boolean>(false);
  const [sideMenuState, setSideMenuState] = useState<SideMenuState>({
    open: false,
    view: 'Search'
  });
  const [toolboxState, setToolboxState] = useState<ToolboxState>({
    open: false,
    view: 'Help'
  });
  const [searchLabelsState, setSearchLabelsState] = useState<SearchLabelState>({
    labelSearchFilter: undefined,
    structuresFilter: [],
    unitsFilter: []
  });
  const [navBarMobileOpen, setnavBarMobileOpen] = useState(false);
  const [headerRef, { height: headerHeight }] = useElementSize();
  const [subjectViewState, setSubjectViewState] = useState<SubjectViewState>({
    reset: false,
    labelsOn: true,
    labelsSize: 0.9,
    axesOn: true,
    contextOn: !isMobile,
    contextSize: 260,
    labelsOnHover: true,
    labelsWithNumbers: false
  });

  const [annotationsState, setAnnotationsState] = useState<AnnotationsState>({
    active: false,
    mode: 'Brush',
    brushWidth: 5,
    colour: '#B71C1C',
    fontSize: 2,
    reset: false
  });

  const { viewerType, subjectSlug, areaSlug } = useParams();
  const { data: modelConfig } = useSubjectConfig(
    subjectSlug,
    viewerType as ViewerType | undefined,
    areaSlug
  );
  const { data: areaConfig } = useAreaConfig(areaSlug);
  const [searchParams, setSearchParams] = useSearchParams();
  const title = areaConfig ? areaConfig.title : '';
  const dialogTitle = `Please note`;
  const dialogDescription = `Navigating home will clear your currently selected ${areaConfig?.subjectName.singular}. \n\nWould you like to proceed?`;
  const dialogOkText = 'OK';
  const dialogCancelText = 'Cancel';

  const openAreaSlugModal = (e: any) => {
    e.preventDefault();
    if (isMobile) {
      setSideMenuOpen(false);
      setToolboxState({ open: false, view: 'Help' });
    }
    setAreaNavigationWarningState(true);
  };

  const closeAreaSlugModal = (e: any) => {
    e.preventDefault();
    setAreaNavigationWarningState(false);
  };

  const navigate = useNavigate();
  const navigateToAreaLandingPage = (e: any) => {
    e.preventDefault();
    setAreaNavigationWarningState(false);
    if (!areaSlug) return;
    const path = `/view/${areaSlug}`;
    navigate(path);
    setSideMenuState({ open: true, view: 'Search' });
    setToolboxState({ open: false, view: 'Help' });
    setSearchLabelsState({ ...searchLabelsState, labelSearchFilter: undefined });
  };

  const openSideMenuView = (view: SideMenuViews) => {
    return (e: any) => {
      e.preventDefault();
      if (view === 'Details') {
        searchParams.delete('selectedLabel');
        setSearchParams(searchParams);
      }
      setnavBarMobileOpen(false);
      if (isMobile) {
        setToolboxOpen(false);
      }
      setSideMenuView(view);
      DXPAnalytics.trackAction({
        name: 'OPEN_SIDE_MENU',
        context: { ...getCommonAnalyticsContext(), context5: view }
      });
    };
  };

  const setSideMenuView = (view: SideMenuViews) => {
    setSideMenuState({ open: true, view: view });
  };

  const setSideMenuOpen = (open: boolean) => {
    setSideMenuState({ ...sideMenuState, open: open });
  };

  const setToolboxView = (view: ToolboxViews) => {
    if (isMobile) {
      setSideMenuOpen(false);
    }
    setToolboxState({ open: true, view: view });
    DXPAnalytics.trackAction({
      name: 'OPEN_TOOLBOX_VIEW',
      context: { ...getCommonAnalyticsContext(), context5: view }
    });
  };

  const setToolboxOpen = (open: boolean) => {
    setToolboxState({ ...toolboxState, open: open });
  };

  const toggleResetView = () => {
    setSubjectViewState({ ...subjectViewState, reset: true });
    DXPAnalytics.trackAction({
      name: 'RESET_VIEW',
      context: getCommonAnalyticsContext()
    });
  };

  const onChangeLabelSearchFilter: ChangeEventHandler<HTMLInputElement> | undefined = (e: any) => {
    setSearchLabelsState({ ...searchLabelsState, labelSearchFilter: e.target.value });
  };

  const getUpdatedLabels = (): LabelConfig[] => {
    return (modelConfig?.labels ?? [])
      .filter((label) =>
        searchLabelsState.labelSearchFilter
          ? label.title.toLowerCase().includes(searchLabelsState.labelSearchFilter.toLowerCase())
          : true
      )
      .filter((label) =>
        searchLabelsState.structuresFilter.length
          ? searchLabelsState.structuresFilter.some((sf) => label.primaryCategoryId.id === sf.id)
          : true
      )
      .filter((label) =>
        searchLabelsState.unitsFilter.length
          ? searchLabelsState.unitsFilter.some((uf) => label.unitIds.some((id) => id === uf.id))
          : true
      )
      .sort((a, b) => a.title.localeCompare(b.title));
  };

  useEffect(() => {
    // handle browser back button click to reset the side menu view
    const handlePopState = (e: any) => {
      if (e.target.location.pathname === `/view/${areaSlug}`) {
        navigateToAreaLandingPage(e);
      }
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <div className="flex flex-col h-screen">
      {areaNavigationWarningState && (
        <Modal
          title={dialogTitle}
          description={dialogDescription}
          hideXButton={true}
          okButtonTitle={dialogOkText}
          okAction={navigateToAreaLandingPage}
          cancelButtonTitle={dialogCancelText}
          cancelAction={closeAreaSlugModal}
        />
      )}
      <Header
        title={title}
        openAreaSlugModal={openAreaSlugModal}
        sideMenuState={sideMenuState}
        setToolboxView={setToolboxView}
        toolboxState={toolboxState}
        toggleResetView={toggleResetView}
        navBarMobileOpen={navBarMobileOpen}
        setnavBarMobileOpen={setnavBarMobileOpen}
        openSideMenuView={openSideMenuView}
        areaConfig={areaConfig}
        modelConfigAvailable={!!modelConfig}
        ref={headerRef}
      />
      <SideMenu
        open={sideMenuState.open}
        setOpen={setSideMenuOpen}
        view={sideMenuState.view}
        topOffset={headerHeight}
        subjectViewState={subjectViewState}
        setSubjectViewState={setSubjectViewState}
        searchLabelsState={searchLabelsState}
        setSearchLabelsState={setSearchLabelsState}
        onChangeLabelSearchFilter={onChangeLabelSearchFilter}
        getUpdatedLabels={getUpdatedLabels}
      />
      <Toolbox
        open={toolboxState.open}
        setOpen={setToolboxOpen}
        view={toolboxState.view}
        topOffset={headerHeight}
        subjectViewState={subjectViewState}
        setSubjectViewState={setSubjectViewState}
        annotationsState={annotationsState}
        setAnnotationsState={setAnnotationsState}
      />

      {isMobile && (
        <NavBarMenuMobile
          headerHeight={headerHeight}
          navBarMobileOpen={navBarMobileOpen}
          setnavBarMobileOpen={setnavBarMobileOpen}
          sideMenuState={sideMenuState}
          openSideMenuView={openSideMenuView}
          areaConfig={areaConfig}
          modelConfigAvailable={!!modelConfig}
        />
      )}

      <main className="flex-1 layout">
        <Outlet
          context={[
            sideMenuState,
            setSideMenuState,
            subjectViewState,
            setSubjectViewState,
            toggleResetView,
            setToolboxView,
            toolboxState,
            searchLabelsState,
            getUpdatedLabels,
            headerHeight,
            annotationsState,
            setAnnotationsState
          ]}
        />
      </main>
    </div>
  );
};
