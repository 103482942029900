import { SideMenuViews, Sheet, SheetContent } from '@/components';
import { NavBarMenu } from '../Header/NavBarMenu';
import { AreaConfig } from '#server/models';

export interface NavBarMenuMobileProps {
  headerHeight: number;
  navBarMobileOpen: boolean;
  setnavBarMobileOpen: (on: boolean) => void;
  sideMenuState: {
    open: boolean;
    view: SideMenuViews;
  };
  openSideMenuView: (view: SideMenuViews) => (e: any) => void;
  areaConfig: AreaConfig | undefined;
  modelConfigAvailable: boolean;
}
export const NavBarMenuMobile = ({
  headerHeight,
  navBarMobileOpen,
  setnavBarMobileOpen,
  sideMenuState,
  openSideMenuView,
  areaConfig,
  modelConfigAvailable
}: NavBarMenuMobileProps) => {
  return (
    <Sheet open={navBarMobileOpen} onOpenChange={setnavBarMobileOpen} modal={false}>
      <SheetContent
        side="top"
        className="z-[500] border-[#1A1A1A] focus-visible:outline-none"
        style={{ top: `${headerHeight}px` }}
        showDefaultCloseButton={false}
      >
        <NavBarMenu
          sideMenuState={sideMenuState}
          openSideMenuView={openSideMenuView}
          areaConfig={areaConfig}
          modelConfigAvailable={modelConfigAvailable}
        />
      </SheetContent>
    </Sheet>
  );
};
