import { useParams } from 'react-router-dom';
import { ThreeDeeViewer } from '../ThreeDeeViewer';

export const ViewerRouter = () => {
  const { viewerType } = useParams();

  switch (viewerType) {
    case '3D':
      return <ThreeDeeViewer />;
    default:
      return <></>;
  }
};
