import { useSubjectConfig, useAreaConfig } from '@/hooks';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { LabelConfig, ViewerType } from '#server/models';

export const LabelDetails = () => {
  const [searchParams] = useSearchParams();
  const { viewerType, subjectSlug, areaSlug } = useParams();
  const [label, setLabel] = useState<LabelConfig | undefined>(undefined);

  const selectedLabelId = searchParams.get('selectedLabel');
  const { data: modelConfig } = useSubjectConfig(
    subjectSlug,
    viewerType as ViewerType | undefined,
    areaSlug
  );
  const { data: areaConfig } = useAreaConfig(areaSlug);
  const units = areaConfig?.units.filter((unit) => label?.unitIds.includes(unit.id));
  const primaryCategory = areaConfig?.categoryGroups
    .find((gp) => gp.id === label?.primaryCategoryId.groupId)
    ?.categories.find((c) => c.id === label?.primaryCategoryId.id);

  useEffect(() => {
    const selectedLabel = modelConfig?.labels?.find((item) => item.slug === selectedLabelId);
    setLabel(selectedLabel);
  }, [selectedLabelId]);

  return (
    <>
      {label ? (
        <div className="flex flex-col gap-y-8 mt-8 p-4 text-base">
          <div>
            <div className="font-bold">{label.title}</div>
            <div className="mt-5">{label.description}</div>
          </div>
          <div>
            <div className="font-bold">Structure category</div>
            <div className="text-sm mt-5">
              <div
                className="inline-block rounded-2xl bg-black px-5 py-1 border-[3px]"
                style={{ borderColor: primaryCategory?.color }}
              >
                {primaryCategory?.name}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col mt-8 p-4 text-base font-normal">
          Please select a label or a structure to view its details in this section.
        </div>
      )}
    </>
  );
};
