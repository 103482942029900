import { Discipline, SearchItem } from '#server/models';
import { CubeIcon, MicroscopeIcon, ThreeSixtyIcon } from '../Common';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useWindowSize } from 'usehooks-ts';
import { ScreenSize } from '../Common/Device';
import { DXPAnalytics } from '@curtin-dxp/web-client';
import { getCommonAnalyticsContext } from '@/lib/utils';

const renderIcon = (type: string) => {
  switch (type) {
    case '3D':
      return <CubeIcon />;
    case 'Image':
      return <MicroscopeIcon />;
    case '360':
      return <ThreeSixtyIcon />;
    default:
      return <></>;
  }
};
export const ResultItem = ({
  searchResult,
  areaSlug,
  areaSubjectName,
  areaLabelName,
  disciplines,
  setOpenSideMenu
}: {
  searchResult: SearchItem;
  areaSlug: string;
  areaSubjectName: string;
  areaLabelName: string;
  disciplines: Discipline[];
  setOpenSideMenu: (open: boolean) => void;
}) => {
  const { subjectName, subjectSlug, labelName, labelSlug, thumbnailUrl, discipline, type } =
    searchResult;
  const navigate = useNavigate();
  const navigateToSubject = (e: { preventDefault: () => void }, labelName: string | undefined) => {
    e.preventDefault();
    const basePath = `/view/${areaSlug}/${type}/${subjectSlug}`;
    const path = labelName ? `${basePath}?selectedLabel=${labelSlug}` : basePath;
    navigate(path, { state: { key: uuidv4() } }); // adding state key ensures we trigger a refresh in Subject component
    setOpenSideMenu(false);
    DXPAnalytics.trackAction({
      name: 'SELECT_SEARCH_RESULT',
      context: {
        ...getCommonAnalyticsContext(),
        context5: path
      }
    });
  };

  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth <= ScreenSize.mobile;

  return (
    <a href="#" onClick={(e) => navigateToSubject(e, labelName)}>
      <div
        className="flex justify-between w-[310px] h-[130px] lg:w-[335px] lg:h-[176px] bg-[#303030]
          rounded-2xl p-4"
      >
        <div className="w-[118px] lg:h-[144px] h-[100px] flex flex-col justify-between">
          <div>
            <div className="uppercase text-[10px] lg:text-xs font-normal text-[#C3D82E]">
              {disciplines.find((d) => d.id.toString() === discipline)?.name}
            </div>
            <div
              className={`${isMobile ? 'line-clamp-3' : 'line-clamp-4'} first-letter:uppercase
              text-sm lg:text-base font-semibold mt-1`}
            >
              {labelName ? `${labelName} in ${subjectName}` : subjectName}
            </div>
          </div>
          <div className="flex gap-2">
            <div className="width-6 height-6">{renderIcon(type)}</div>
            <div
              className="uppercase text-[10px] lg:text-xs font-normal rounded-2xl border-[1px]
                border-[#E8E8E8] py-1 px-3"
            >
              {labelName ? areaLabelName : areaSubjectName}
            </div>
          </div>
        </div>
        <div
          className="ml-8 w-[100px] h-[100px] lg:w-[153px] lg:h-[144px] overflow-hidden
            rounded-lg"
        >
          <img
            src={thumbnailUrl}
            className="rounded-2xl w-full h-full"
            alt={labelName ? `${labelName} in ${subjectName}` : subjectName}
          />
        </div>
      </div>
    </a>
  );
};
