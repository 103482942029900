import { trpc } from '@/data-loaders';

export const useAreaConfig = (areaSlug?: string | undefined) => {
  return trpc.getAreaConfig.useQuery(
    { areaSlug: areaSlug ?? '' },
    {
      retry: false,
      cacheTime: 1000 * 60 * 60 * 8, // cache for 8 hours
      staleTime: 1000 * 60 * 60 * 8, // don't consider the data stale until 8 hours
      enabled: !!areaSlug
    }
  );
};
