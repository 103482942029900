interface CategoryPros {
  categoryId: any;
  categoryName: string | null;
}

export const CategoryDetail: React.FC<CategoryPros> = ({ categoryId, categoryName }) => (
  <div key={categoryId} className="inline-block rounded-[20px] bg-black px-2 py-1 border-[0.5px]">
    {categoryName}
  </div>
);
