import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { useDeviceType } from '@/hooks';
import { CrossIcon } from '../Icons';

export interface ModalProps {
  title: string;
  description: string;
  hideXButton?: boolean;
  okButtonTitle?: string;
  okAction?: (e: any) => void;
  cancelButtonTitle?: string;
  cancelAction?: (e: any) => void;
}
export const Modal = ({
  title,
  description,
  hideXButton,
  okButtonTitle,
  okAction,
  cancelButtonTitle,
  cancelAction
}: ModalProps) => {
  const { isMobile } = useDeviceType();
  return (
    <Dialog defaultOpen>
      <DialogContent
        className={`dialog ${isMobile ? ' w-4/5 max-h-fit' : 'px-8 py-12 w-1/3 max-h-fit'}
        bg-[#303030] z-[300]`}
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        hideXButton={hideXButton}
      >
        <DialogHeader className={`${isMobile ? 'text-left' : ''}}`}>
          <div className="absolute right-4 top-4 hover:stroke-[#E8E8E8]">
            {cancelAction !== undefined ? (
              <Button variant="link" onClick={cancelAction} aria-label="Close">
                <CrossIcon className="w-5 h-5" />
              </Button>
            ): null }
          </div>
          <DialogTitle
            className={`pb-6 text-white ${isMobile ? 'pt-6 text-[24px] text-left leading-7' : 'text-[34px] leading-10'}
            font-bold `}
          >
            {title}
          </DialogTitle>
          <DialogDescription
            className={`mt-0 pb-10 ${isMobile ? 'text-sm text-left' : 'text-lg'} text-white 
            font-normal whitespace-pre-wrap`}
          >
            {description}
          </DialogDescription>
          <DialogFooter className="flex flex-row gap-x-4 sm:justify-start">
            {okButtonTitle ? (
              <Button
                variant="secondary"
                className={`${isMobile ? 'text-sm' : 'text-base px-7'} w-[120px] text-left 
                rounded-s-md font-semibold leading-7 focus-visible:ring-2
                focus-visible:ring-offset-2`}
                onClick={okAction}
                aira-label={okButtonTitle}
              >
                {okButtonTitle}
              </Button>
            ) : null}
            {cancelButtonTitle ? (
              <Button
                variant="outline"
                className={`${isMobile ? 'text-sm' : 'text-base px-7'} w-[120px]  bg-transparent text-left 
                rounded-s-md font-semibold leading-7 focus-visible:ring-2
                focus-visible:ring-offset-2`}
                onClick={cancelAction}
                aira-label={cancelButtonTitle}
              >
                {cancelButtonTitle}
              </Button>
            ) : null}
          </DialogFooter>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
