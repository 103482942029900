import { Input, MagnifyingGlassIcon } from '@/components';
import {
  ModelType,
  ResultType,
  SearchFilterDropDown,
  SearchSubjectsState
} from './SearchFilterDropDown';
import { AreaConfig, CategoryGroup } from '#server/models';
import { useState } from 'react';
import { useDeviceType } from '@/hooks';
import { DXPAnalytics } from '@curtin-dxp/web-client';
import { getCommonAnalyticsContext } from '@/lib/utils';

export const SearchSubjectsView = ({
  searchSubjectsState,
  setSearchSubjectsState,
  areaConfig,
  setSearchSubjects,
  categories
}: {
  searchSubjectsState: SearchSubjectsState;
  setSearchSubjectsState: React.Dispatch<React.SetStateAction<SearchSubjectsState>>;
  areaConfig: AreaConfig | undefined;
  setSearchSubjects: (searchTerms: string | undefined) => void;
  categories: CategoryGroup[] | null;
}) => {
  const [searchInput, setSearchInput] = useState('');
  const units = areaConfig?.units.toSorted((a, b) => a.udc.localeCompare(b.udc)) ?? [];
  const systems =
    categories
      ?.find((obj) => obj.name === 'Systems')
      ?.categories.toSorted((a, b) => a.name.localeCompare(b.name)) ?? [];
  const regions =
    categories
      ?.find((obj) => obj.name === 'Regions')
      ?.categories.toSorted((a, b) => a.name.localeCompare(b.name)) ?? [];
  const disciplines =
    areaConfig?.disciplines.toSorted((a, b) => a.name.localeCompare(b.name)) ?? [];
  const modelTypes: ModelType[] = [
    { id: '3D', name: '3D model' },
    { id: 'Image', name: '2D image' },
    { id: '360', name: '360 image' }
  ];
  const resultTypes: ResultType[] = [
    { id: 'subject', name: areaConfig?.subjectName.plural ?? '' },
    { id: 'label', name: areaConfig?.labelName.plural ?? '' }
  ];

  const resetAllFilters = () => {
    setSearchSubjectsState({
      unitsFilter: [],
      systemsFilter: [],
      regionsFilter: [],
      disciplinesFilter: [],
      modelTypesFilter: [],
      resultTypesFilter: []
    });
  };

  const { isMobile } = useDeviceType();

  return (
    <div className={`${isMobile ? 'p-4 pt-0' : 'p-4'}`}>
      <p className="text-xl font-bold">Search {`${areaConfig?.subjectName.plural}`}</p>
      {areaConfig && (
        <div className="relative mt-8">
          <Input
            aria-label={`Search ${areaConfig?.subjectName.plural}`}
            placeholder={`Search for a ${areaConfig.subjectName.singular} or ${areaConfig.labelName.singular}`}
            className="bg-[#303030] border-none text-[#E8E8E8] placeholder:text-[#E8E8E8]
              focus-visible:ring-0 focus-visible:bg-[#4B4B4B]"
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={(e) => {
              const target = e.target as HTMLInputElement;
              if (e.key === 'Enter') {
                e.preventDefault();
                setSearchSubjects(target.value);
                DXPAnalytics.trackAction({
                  name: 'SEARCH_TERM',
                  context: { ...getCommonAnalyticsContext(), context5: searchInput }
                });
              } else {
                setSearchInput(target.value);
              }
            }}
            value={searchInput}
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              setSearchSubjects(searchInput);
              DXPAnalytics.trackAction({
                name: 'SEARCH_TERM',
                context: { ...getCommonAnalyticsContext(), context5: searchInput }
              });
            }}
            aria-label="Search"
            tabIndex={-1}
          >
            <MagnifyingGlassIcon
              className="absolute right-3 top-1/3 transform -translate-y-1/2 text-gray-500 z-10 h-4
                w-4"
            />
          </button>
        </div>
      )}
      <div>
        <a
          href="#"
          className="text-[#C3D82E] underline"
          onClick={(e) => {
            e.preventDefault();
            resetAllFilters();
            setSearchInput('');
            setSearchSubjects(undefined);
            DXPAnalytics.trackAction({
              name: 'SEARCH_SHOW_ALL_SUBJECTS',
              context: getCommonAnalyticsContext()
            });
          }}
        >
          {`Show all ${areaConfig?.subjectName.plural}`}
        </a>
      </div>

      <div className="mt-10 flex flex-col">
        <div>
          <div className="text-base leading-6 flex justify-between">
            <p className="font-semibold">Filter by</p>
            <a
              href="#"
              className="text-[#C3D82E] underline"
              onClick={(e) => {
                e.preventDefault();
                resetAllFilters();
                DXPAnalytics.trackAction({
                  name: 'RESET_SUBJECT_SEARCH_FILTERS',
                  context: getCommonAnalyticsContext()
                });
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  resetAllFilters();
                  DXPAnalytics.trackAction({
                    name: 'RESET_SUBJECT_SEARCH_FILTERS',
                    context: getCommonAnalyticsContext()
                  });
                }
              }}
            >
              Reset all
            </a>
          </div>
        </div>
        <SearchFilterDropDown
          state={searchSubjectsState}
          setState={setSearchSubjectsState}
          stateFilterFieldName="unitsFilter"
          stateFilterValues={searchSubjectsState.unitsFilter}
          fullOptionList={units}
          optionDisplayKey={'udc'}
          dropDownName="Unit"
        />
        <SearchFilterDropDown
          state={searchSubjectsState}
          setState={setSearchSubjectsState}
          stateFilterFieldName="systemsFilter"
          stateFilterValues={searchSubjectsState.systemsFilter}
          fullOptionList={systems || []}
          optionDisplayKey={'name'}
          dropDownName="System"
        />
        <SearchFilterDropDown
          state={searchSubjectsState}
          setState={setSearchSubjectsState}
          stateFilterFieldName="regionsFilter"
          stateFilterValues={searchSubjectsState.regionsFilter}
          fullOptionList={regions || []}
          optionDisplayKey={'name'}
          dropDownName="Region"
        />
        <SearchFilterDropDown
          state={searchSubjectsState}
          setState={setSearchSubjectsState}
          stateFilterFieldName="disciplinesFilter"
          stateFilterValues={searchSubjectsState.disciplinesFilter}
          fullOptionList={disciplines}
          optionDisplayKey={'name'}
          dropDownName="Discipline"
        />
        <SearchFilterDropDown
          state={searchSubjectsState}
          setState={setSearchSubjectsState}
          stateFilterFieldName="modelTypesFilter"
          stateFilterValues={searchSubjectsState.modelTypesFilter}
          fullOptionList={modelTypes}
          optionDisplayKey={'name'}
          dropDownName="Model type"
        />
        <SearchFilterDropDown
          state={searchSubjectsState}
          setState={setSearchSubjectsState}
          stateFilterFieldName="resultTypesFilter"
          stateFilterValues={searchSubjectsState.resultTypesFilter}
          fullOptionList={resultTypes}
          optionDisplayKey={'name'}
          dropDownName={`${areaConfig?.subjectName.singular ?? ''} or ${areaConfig?.labelName.singular ?? ''}`}
        />
      </div>
    </div>
  );
};
