import { useEffect } from 'react';
import { MathUtils } from 'three';
import { HOLD_EVENT_TYPE, KeyboardKeyHold } from 'hold-event';
import { CameraControls } from '@react-three/drei';
import { DXPAnalytics } from '@curtin-dxp/web-client';
import { getCommonAnalyticsContext } from '@/lib/utils';

interface EventListenerHandlerProps {
  resetViewHandler: () => void | null;
  cameraControlsRef: React.MutableRefObject<CameraControls | null>;
}

// CameraControlsWrapper to expose the _truckInternal method to set the dragToOffset flag to true
class CameraControlsWrapper {
  private cameraControlsWrapper: any;

  constructor(cameraControls: any) {
    this.cameraControlsWrapper = cameraControls;
  }

  truckOffset(deltaX: number, deltaY: number, dragToOffset: boolean) {
    if (this.cameraControlsWrapper?._truckInternal) {
      this.cameraControlsWrapper._truckInternal(deltaX, deltaY, dragToOffset);
    }
  }
}

export const toggleFullScreen = () => {
  if (!document.fullscreenElement) {
    const docElm = document.documentElement as any;
    if (docElm.requestFullscreen) {
      docElm.requestFullscreen();
    } else if (docElm.mozRequestFullScreen) {
      docElm.mozRequestFullScreen();
    } else if (docElm.webkitRequestFullScreen) {
      docElm.webkitRequestFullScreen();
    } else if (docElm.msRequestFullscreen) {
      docElm.msRequestFullscreen();
    }

    DXPAnalytics.trackAction({
      name: 'TOGGLE_FULLSCREEN_ON',
      context: getCommonAnalyticsContext()
    });
  } else {
    const doc = document as any;
    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen();
    } else if (doc.webkitCancelFullScreen) {
      doc.webkitCancelFullScreen();
    } else if (doc.msExitFullscreen) {
      doc.msExitFullscreen();
    }
    DXPAnalytics.trackAction({
      name: 'TOGGLE_FULLSCREEN_OFF',
      context: getCommonAnalyticsContext()
    });
  }
};

export const NavigationControlsEventListener: React.FC<EventListenerHandlerProps> = ({
  resetViewHandler,
  cameraControlsRef
}) => {
  const cameraControls = cameraControlsRef?.current;
  const altLeft = new KeyboardKeyHold('AltLeft', 100);
  const altRight = new KeyboardKeyHold('AltRight', 100);
  const keyShiftLeft = new KeyboardKeyHold('ShiftLeft', 100);
  const keyShiftRight = new KeyboardKeyHold('ShiftRight', 100);
  const arrowLeft = new KeyboardKeyHold('ArrowLeft', 100);
  const arrowRight = new KeyboardKeyHold('ArrowRight', 100);
  const arrowUp = new KeyboardKeyHold('ArrowUp', 100);
  const arrowDown = new KeyboardKeyHold('ArrowDown', 100);

  useEffect(() => {
    const altKeyEventListenerCallback = (event: any) => {
      if (arrowLeft.holding) handleOrbitControl(event, 1, 0);
      if (arrowRight.holding) handleOrbitControl(event, -1, 0);
      if (arrowUp.holding) handleOrbitControl(event, 0, 1);
      if (arrowDown.holding) handleOrbitControl(event, 0, -1);
    };

    const shiftKeyEventListenerCallback = (event: any) => {
      if (arrowLeft.holding) handlePanControl(event, 1, 0);
      if (arrowRight.holding) handlePanControl(event, -1, 0);
      if (arrowUp.holding) handlePanControl(event, 0, 1);
      if (arrowDown.holding) handlePanControl(event, 0, -1);
    };

    // Add event listeners for keydown events to prevent default behavior of the control keys
    // Handle 'home' key to reset the view, handle '+' and '-' keys to handle dolly control
    document.addEventListener('keydown', handleKeyDown);

    // Add event listeners for the shift key to handle pan controls
    keyShiftLeft.addEventListener(HOLD_EVENT_TYPE.HOLDING, shiftKeyEventListenerCallback);
    keyShiftRight.addEventListener(HOLD_EVENT_TYPE.HOLDING, shiftKeyEventListenerCallback);

    // Add event listeners for the alt key to handle orbit controls
    altLeft.addEventListener(HOLD_EVENT_TYPE.HOLDING, altKeyEventListenerCallback);
    altRight.addEventListener(HOLD_EVENT_TYPE.HOLDING, altKeyEventListenerCallback);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      keyShiftLeft.removeEventListener(HOLD_EVENT_TYPE.HOLDING, shiftKeyEventListenerCallback);
      keyShiftRight.removeEventListener(HOLD_EVENT_TYPE.HOLDING, shiftKeyEventListenerCallback);
      altLeft.removeEventListener(HOLD_EVENT_TYPE.HOLDING, altKeyEventListenerCallback);
      altRight.removeEventListener(HOLD_EVENT_TYPE.HOLDING, altKeyEventListenerCallback);
    };
  }, [cameraControls]);

  const handleKeyDown = (event: any) => {
    if (
      event.key === 'Home' ||
      event.key === '+' ||
      event.key === '-' ||
      (event.altKey &&
        (event.key === 'ArrowLeft' ||
          event.key === 'ArrowRight' ||
          event.key === 'ArrowUp' ||
          event.key === 'ArrowDown'))
    ) {
      event.preventDefault();

      // manual handle home key to reset the view
      if (event.key === 'Home') {
        resetViewHandler();
      }

      // manual handle plus and minus keys as plus key are not available in hold-event lib
      if (event.key === '+' || event.key === '-') {
        const zoomFactor = event.key === '+' ? 10 : -10;
        cameraControls?.dolly(zoomFactor, true);
      }
    }
  };

  const handleOrbitControl = (
    event: any,
    horizontalDirection: number,
    verticalDirection: number
  ) => {
    cameraControls?.rotate(
      horizontalDirection * 0.1 * MathUtils.DEG2RAD * event.deltaTime,
      verticalDirection * 0.1 * MathUtils.DEG2RAD * event.deltaTime,
      true
    );
  };

  const handlePanControl = (event: any, horizontalOffset: number, verticalOffset: number) => {
    const cameraControlsWrapper = new CameraControlsWrapper(cameraControls);
    cameraControlsWrapper.truckOffset(
      horizontalOffset * 0.1 * event.deltaTime,
      verticalOffset * 0.1 * event.deltaTime,
      true
    );
  };

  return null;
};
