export const MicroscopeIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#C3D82E" fill-opacity="0.2" />
    <g clip-path="url(#clip0_4509_22125)">
      <path
        d="M11.0712 12.928C11.1454 12.928 11.2197 12.8999 11.2763 12.8438L14.2906 9.85246C14.4039 9.74002 14.4039 9.55779 14.2906 9.44534C13.8149 8.97311 13.8149 8.20378 14.2906 7.73154C14.7524 7.27157 15.5563 7.27209 16.0171 7.73103C16.0714 7.78561 16.1453 7.81584 16.2225 7.81584H16.2228C16.2999 7.81584 16.3735 7.78544 16.4278 7.73154L17.6905 6.47875C17.7448 6.42486 17.7754 6.35163 17.7754 6.27528C17.7754 6.19894 17.7448 6.1257 17.6905 6.07181L14.9003 3.30265C14.7915 3.19469 14.5988 3.19469 14.4901 3.30265L8.07587 9.66799C8.02156 9.72188 7.99093 9.79512 7.99093 9.87146C7.99093 9.94781 8.02156 10.021 8.07587 10.0749L10.866 12.8436C10.9226 12.8999 10.9969 12.9279 11.071 12.9279L11.0712 12.928ZM13.6917 9.63258L11.0712 12.233L8.69116 9.87146L14.6951 3.91324L17.0752 6.27528L16.2068 7.13667C15.5201 6.6425 14.4901 6.7166 13.8798 7.32512C13.2483 7.95212 13.1857 8.93425 13.6917 9.63258Z"
        fill="#C3D82E"
      />
      <path
        d="M9.44491 13.472C9.52201 13.472 9.59564 13.4416 9.64995 13.3878L10.7373 12.3087C10.8506 12.1963 10.8506 12.0141 10.7373 11.9016L9.02682 10.2035C8.91803 10.0956 8.72535 10.0956 8.61657 10.2035L7.52923 11.2826C7.41592 11.395 7.41592 11.5772 7.52923 11.6897L9.23987 13.3878C9.29417 13.4416 9.36797 13.472 9.44491 13.472ZM10.122 12.1053L9.44491 12.7772L8.14452 11.4862L8.82161 10.8143L10.122 12.1053Z"
        fill="#C3D82E"
      />
      <path
        d="M17.171 6.24926C17.2481 6.24926 17.3218 6.21886 17.3761 6.16497L18.415 5.13362C18.5283 5.02117 18.5283 4.83894 18.415 4.7265L16.2562 2.58433C16.1429 2.47189 15.9592 2.47189 15.8459 2.58433L14.8072 3.615C14.7528 3.66889 14.7222 3.74213 14.7222 3.81847C14.7222 3.89482 14.7528 3.96805 14.8072 4.02194L16.9658 6.1648C17.0201 6.21869 17.0939 6.24909 17.1709 6.24909L17.171 6.24926ZM17.7997 4.93014L17.171 5.55421L15.4224 3.81847L16.0511 3.19492L17.7997 4.93014Z"
        fill="#C3D82E"
      />
      <path
        d="M15.1559 10.3758C15.3664 10.3758 15.5784 10.3392 15.7817 10.2634C16.0228 10.1746 16.2466 10.0322 16.4276 9.85226C16.5143 9.7697 16.5982 9.66606 16.6723 9.54861C17.1268 8.85476 17.025 7.91754 16.4276 7.32422C15.7488 6.64731 14.5596 6.64679 13.8796 7.32474C13.7748 7.42924 13.6841 7.54566 13.6101 7.67158C13.4089 7.9989 13.3209 8.39358 13.3647 8.77928C13.4067 9.18191 13.5894 9.56312 13.8798 9.85209C14.2242 10.1934 14.6865 10.3756 15.1558 10.3756L15.1559 10.3758ZM15.154 7.37501C15.48 7.37501 15.7866 7.50144 16.017 7.731C16.4224 8.13362 16.4924 8.76788 16.1836 9.23891C16.1322 9.32043 16.0785 9.38676 16.0215 9.44134C15.895 9.56674 15.743 9.66347 15.5787 9.7241C15.1331 9.89061 14.6286 9.78041 14.2905 9.44514C14.0911 9.24668 13.9704 8.99536 13.9421 8.71865C13.9417 8.71761 13.9417 8.71641 13.9415 8.7152C13.9118 8.45593 13.971 8.19062 14.1086 7.96677C14.1614 7.87678 14.22 7.80147 14.2909 7.73065C14.5215 7.50127 14.828 7.37466 15.154 7.37466V7.37501Z"
        fill="#C3D82E"
      />
      <path
        d="M13.9767 18.9576C14.0056 18.9576 14.0353 18.9531 14.0646 18.9441C16.5713 18.1541 18.2559 15.8708 18.2559 13.2612C18.2559 11.7481 17.6822 10.3053 16.6404 9.19884C16.5792 9.13355 16.49 9.10436 16.4011 9.10885C16.3113 9.11731 16.2309 9.16671 16.183 9.24271C16.1339 9.32026 16.0801 9.38659 16.0232 9.44117C15.8967 9.56657 15.7447 9.6633 15.5804 9.72393C15.4887 9.75761 15.4203 9.83517 15.3989 9.92965C15.3771 10.0241 15.4048 10.1231 15.4725 10.1934C16.2756 11.0227 16.7179 12.1124 16.7179 13.261C16.7179 15.7069 14.7129 17.6967 12.2486 17.6967C10.5871 17.6967 9.07207 16.7886 8.2949 15.3274C8.24443 15.2329 8.14591 15.1738 8.03817 15.1738H6.97642C6.87929 15.1738 6.78826 15.2222 6.73483 15.3025C6.68104 15.3828 6.6713 15.4847 6.70941 15.5735C7.39503 17.1844 8.75059 18.4125 10.4288 18.9438C10.5813 18.9899 10.7448 18.9078 10.7935 18.757C10.8419 18.6052 10.7578 18.4434 10.6052 18.395C9.23098 17.9604 8.09578 17.0078 7.43402 15.7495H7.86724C8.76764 17.3103 10.4306 18.2724 12.249 18.2724C15.0332 18.2724 17.2984 16.0245 17.2984 13.2609C17.2984 12.0908 16.8925 10.9746 16.1477 10.0796C16.2493 10.0132 16.3444 9.93673 16.4298 9.85192C16.433 9.84916 16.4361 9.8457 16.4392 9.84294C17.2392 10.805 17.6761 12.006 17.6761 13.2609C17.6761 15.6179 16.1541 17.6813 13.8893 18.3948C13.7366 18.4432 13.6522 18.6045 13.7006 18.7564C13.7398 18.879 13.8539 18.9571 13.9772 18.9571L13.9767 18.9576Z"
        fill="#C3D82E"
      />
      <path
        d="M5.78979 15.7502H6.97547C7.13578 15.7502 7.26562 15.6215 7.26562 15.4623C7.26562 15.303 7.13595 15.1743 6.97547 15.1743H5.78979C5.62948 15.1743 5.49964 15.303 5.49964 15.4623C5.49964 15.6215 5.62931 15.7502 5.78979 15.7502Z"
        fill="#C3D82E"
      />
      <path
        d="M8.03819 15.7502H9.39149C9.55179 15.7502 9.68164 15.6215 9.68164 15.4623C9.68164 15.303 9.55197 15.1743 9.39149 15.1743H8.03819C7.87788 15.1743 7.74804 15.303 7.74804 15.4623C7.74804 15.6215 7.87771 15.7502 8.03819 15.7502Z"
        fill="#C3D82E"
      />
      <path
        d="M8.16511 21.5001H16.7764C16.8535 21.5001 16.9272 21.4697 16.9815 21.4158C17.0358 21.3619 17.0664 21.2886 17.0664 21.2123C17.0664 20.7456 16.884 20.307 16.5528 19.9787C16.2193 19.6476 15.7777 19.4653 15.3093 19.4653H9.63207C8.66326 19.4653 7.87478 20.2492 7.87478 21.2123C7.87478 21.3714 8.00445 21.5002 8.16494 21.5002L8.16511 21.5001ZM16.4509 20.9244H8.49112C8.62027 20.4172 9.08291 20.041 9.63224 20.041H15.3095C15.6228 20.041 15.9185 20.1637 16.1427 20.3856C16.2945 20.5362 16.3996 20.7219 16.4509 20.9242V20.9244Z"
        fill="#C3D82E"
      />
    </g>
    <defs>
      <clipPath id="clip0_4509_22125">
        <rect width="13" height="19" fill="white" transform="matrix(-1 0 0 1 18.5 2.5)" />
      </clipPath>
    </defs>
  </svg>
);
