import { useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { Circle } from '../ThreeDeeViewer/Experience/Context/Circle';
import { Group, Mesh, Object3DEventMap, MeshBasicMaterial } from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { AreaConfig } from '#server/models';
import { PerspectiveCamera } from '@react-three/drei';
import { useLoaderWithSignedCache } from '@/hooks';
import { calculateDistanceMultiplier } from '@/lib/utils';
import { useWindowSize } from 'usehooks-ts';

export const Experience = ({ areaConfig }: { areaConfig: AreaConfig }) => {
  const landingDemoConfig = areaConfig.landingDemo!;

  const { size } = useThree();
  const { width: windowWidth } = useWindowSize();
  const distanceMultiplier = calculateDistanceMultiplier(windowWidth);
  const groupRef = useRef<Group<Object3DEventMap> | null>(null);
  const demoModel = useLoaderWithSignedCache(FBXLoader, landingDemoConfig.modelPath);
  demoModel.traverse((child) => {
    if (child.type === 'Mesh') {
      const mesh = child as Mesh;
      mesh.material = new MeshBasicMaterial({
        wireframe: true,
        color: 0xc3d82e,
        transparent: true,
        opacity: 0.1
      });
    }
  });

  const radius = Math.min(size.width / 2, size.height / 2);

  useFrame(() => {
    if (groupRef.current) {
      groupRef.current.rotation.y -= 0.01;
    }
  });

  return (
    <>
      <PerspectiveCamera
        position={[0, 0, radius * 3 * distanceMultiplier]}
        fov={45}
        near={0.1}
        far={10000}
        makeDefault={true}
      />
      <group ref={groupRef}>
        <Circle radius={radius} />
        <Circle radius={radius} rotation={[0, Math.PI / 2, 0]} />
        <Circle radius={radius} rotation={[Math.PI / 2, 0, 0]} />
        <primitive
          scale={[
            landingDemoConfig.scale[0] * radius,
            landingDemoConfig.scale[1] * radius,
            landingDemoConfig.scale[2] * radius
          ]}
          position={[
            landingDemoConfig.position[0] * radius,
            landingDemoConfig.position[1] * radius,
            landingDemoConfig.position[2] * radius
          ]}
          rotation={landingDemoConfig.rotation}
          object={demoModel}
        />
      </group>
    </>
  );
};
